import * as d3 from "d3";
import { FC, useEffect, useRef } from "react";
import * as Colors from "../../globalStyles/Colors";
import { getColorForDifference } from "../../helpers/helpers";
import { ScatterPlotContainer } from "./ScatterPlot.style";

interface DataPoint {
  name: string;
  mean_relevance: number;
  mean_development: number;
}

interface ColoredDataPoint extends DataPoint {
  color: string;
}

interface ScatterPlotProps {
  plotData: DataPoint[];
  handleDataPointClick?: (name: string) => void;
  plotType?: string;
  setSVGStrings?: React.Dispatch<
    React.SetStateAction<{ name: string; data: string }[]>
  >;
}

const ScatterPlot: FC<ScatterPlotProps> = ({
  plotData,
  handleDataPointClick,
  plotType,
  setSVGStrings,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  // Lables und Tooltip Information für ganze Dimensionen
  const xAxisLabel =
    plotType === "Scorecard" ? "Mittlere Relevanz" : "Relevanz";
  const yAxisLabel =
    plotType === "Scorecard" ? "Mittlere Ausprägung" : "Ausprägung";

  const pdfData: ColoredDataPoint[] = [];

  const fontFamily =
    "Segoe UI, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif";

  useEffect(() => {
    if (!svgRef.current) return;
    const svgElement = svgRef.current;
    if (svgElement && svgElement.parentElement) {
      const parentWidth = svgElement.parentElement.clientWidth;
      const parentHeight = svgElement.parentElement.clientHeight;
      const width = "100%";
      const height = "90%";
      const margin = { top: 50, right: -110, bottom: 0, left: 160 };
      const innerWidth = parentWidth * 0.7 - margin.left - margin.right;
      const innerHeight = parentHeight * 0.5 - margin.top - margin.bottom;
      const svg = d3
        .select(svgElement)
        .attr("width", width)
        .attr("height", height)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      // X Achse Pfeilkopf
      svg
        .append("defs")
        .append("marker")
        .attr("id", "arrowheadX")
        .attr("viewBox", "0 0 20 10")
        .attr("refX", "5.5") // x Position des Pfeilkopfs
        .attr("refY", "6") // y Position des Pfeilkopfs
        .attr("markerWidth", "6")
        .attr("markerHeight", "6")
        .append("path")
        // creates a path that forms an arrowhead
        .attr("d", "M 0 0 L 12 6 L 0 12 Z")
        .attr("fill", `${Colors.MintGreen}`);

      // Y Achse Pfeilkopf
      svg
        .append("defs")
        .append("marker")
        .attr("id", "arrowheadY")
        .attr("viewBox", "0 0 10 20")
        .attr("refX", "6")
        .attr("refY", "8")
        .attr("markerWidth", "6")
        .attr("markerHeight", "6")
        .append("path")
        .attr("d", "M 0 12 L 6 0 L 12 12 Z")
        .attr("fill", `${Colors.Yellow}`);

      const xScale = d3
        .scaleLinear()
        .domain([0, 6])
        .range([10, innerWidth + 10]);
      const yScale = d3
        .scaleLinear()
        .domain([0, 6])
        .range([innerHeight - 10, 10]);

      // X Achse hinzufügen
      svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0,${innerHeight})`)
        .call(d3.axisBottom(xScale).tickSize(0))
        .append("text")
        .attr("x", innerWidth / 2)
        .attr("y", 35)
        .attr("fill", "black")
        .attr("font-family", fontFamily)
        .style("font-size", "19px")
        .text(xAxisLabel);

      // x Achse geht von 0 bis 6 in 0.5 Schritten; 0, 0.5, 5.5., 6 sollen nicht angezeigt werden
      svg
        .selectAll(".x.axis .tick")
        .filter(
          (_, i, nodes) =>
            i === 0 ||
            i === 1 ||
            i === nodes.length - 1 ||
            i === nodes.length - 2
        )
        .remove();

      // Auswählen und modifizieren der ticks der x Achse
      svg
        .selectAll(".x.axis .tick text")
        .attr("y", -4)
        .attr("font-family", fontFamily)
        .style("text-anchor", "middle")
        .style("font-size", "14px")
        .style("fill", `${Colors.Black}`);

      // Pfeilkopf zur x Achse hinzufügen
      svg
        .select(".x.axis .domain")
        .attr("stroke", `${Colors.MintGreen}`)
        // creates a horizontal line starting at (10.5, 0.5) and ending at (766.9, 0.5)
        .attr("d", "M10.5,0.5H766.9")
        .attr("stroke-width", 25)
        .attr("marker-end", "url(#arrowheadX)");

      // Y Achse hinzufügen
      svg
        .append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(yScale).tickSize(0))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("x", -innerHeight / 2.9)
        .attr("y", -25)
        .attr("fill", "black")
        .attr("font-family", fontFamily)
        .style("font-size", "19px")
        .text(yAxisLabel);

      // y Achse geht von 0 bis 6 in 0.5 Schritten; 0, 0.5, 5.5., 6 sollen nicht angezeigt werden
      svg
        .selectAll(".y.axis .tick")
        .filter(
          (_, i, nodes) =>
            i === 0 ||
            i === 1 ||
            i === nodes.length - 1 ||
            i === nodes.length - 2
        )
        .remove();

      // Auswählen und modifizieren der ticks der y Achse
      svg
        .selectAll(".y.axis .tick text")
        .attr("x", 9)
        .attr("font-family", fontFamily)
        .style("text-anchor", "end")
        .style("font-size", "14px")
        .style("fill", `${Colors.Black}`);

      // Pfeilkopf zur y Achse hinzufügen
      svg
        .selectAll(".y.axis .domain")
        .attr("stroke", `${Colors.Yellow}`)
        .attr("stroke-width", 25)
        .attr("marker-end", "url(#arrowheadY)");

      // Gestrichelte Diagonale
      svg
        .append("line")
        .attr("x1", xScale(0))
        .attr("y1", yScale(0))
        .attr("x2", xScale(5.5))
        .attr("y2", yScale(5.5))
        .attr("stroke", `${Colors.Grey}`)
        .attr("stroke-dasharray", "4 4");

      // Fucktion um Distanz zwischen zwei Punkten zu berechnen
      function calculateDistance(
        x1: number,
        y1: number,
        x2: number,
        y2: number
      ) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      }

      // Funktion um zu bestimmten ob sich zwei Punkte "stark" überschneiden
      function checkOverlap(
        point1: { x: number; y: number },
        point2: { x: number; y: number }
      ) {
        const distance = calculateDistance(
          point1.x,
          point1.y,
          point2.x,
          point2.y
        );
        return distance <= 0.1; // Starke Überschneidung
      }

      // Funktion gibt boolean array zurück, ob sich ein Punkt mit irgendeinem anderen Punkt überschneidet
      const isOverlapping = plotData.map((d, i) => {
        for (let j = 0; j < plotData.length; j++) {
          if (i !== j) {
            const point1 = {
              x: xScale(d.mean_relevance),
              y: yScale(d.mean_development),
            };
            const point2 = {
              x: xScale(plotData[j].mean_relevance),
              y: yScale(plotData[j].mean_development),
            };
            if (checkOverlap(point1, point2)) {
              return true;
            }
          }
        }
        return false;
      });

      // Funktion gibt alle Punkte zurück, die sich mit dem aktuellen Punkt "stark" überschneiden
      function getOverlappingPoints(currentPoint: DataPoint) {
        const point1 = {
          x: xScale(currentPoint.mean_relevance),
          y: yScale(currentPoint.mean_development),
        };
        let dataPoints: DataPoint[] = [];
        plotData.forEach((otherPoint) => {
          const point2 = {
            x: xScale(otherPoint.mean_relevance),
            y: yScale(otherPoint.mean_development),
          };
          const distance = calculateDistance(
            point1.x,
            point1.y,
            point2.x,
            point2.y
          );
          if (distance < 0.1) dataPoints.push(otherPoint);
        });
        return dataPoints;
      }

      // Circles für alle Datenpunkte hinzufügen
      svg
        .selectAll("circle")
        .data(plotData)
        .enter()
        .append("circle")
        .attr("cx", (d) => xScale(d.mean_relevance))
        .attr("cy", (d) => yScale(d.mean_development))
        .attr("r", 28)
        .attr("fill", (d, i) =>
          isOverlapping[i] ? Colors.MintGreen : `rgba(43, 177, 152, .50)`
        )
        .attr("stroke", `${Colors.Black}`)
        .attr("stroke-width", 2);

      // Tooltip Node erstellen
      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .attr("font-family", fontFamily)
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background", `${Colors.BrightGrey}`)
        .style("border", `1px solid ${Colors.Grey}`)
        .style("padding", "8px")
        .style("border-radius", "4px");

      // Funktion um ToolTip Position upzudaten in Abhängigkeit der Mausposition, damit Tooltip im Fenster der Grafik bleibt
      function updateTooltipPosition(
        event: MouseEvent,
        overlappingPoints: number
      ) {
        const tooltipNode = tooltip.node();
        if (tooltipNode) {
          const tooltipWidth = tooltipNode.offsetWidth;
          const tooltipHeight = tooltipNode.offsetHeight;
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const mouseX = event.pageX;
          const mouseY = event.pageY;

          // Default Position ist unten rechts
          let tooltipX = mouseX + 15;
          let tooltipY = mouseY + 15;

          // Tooltip anpassen wenn er über die rechte Kante hinausgeht
          if (tooltipX + tooltipWidth > windowWidth) {
            tooltipX = mouseX - tooltipWidth - 15;
          }

          // Tooltip anpassen wenn er über die untere Kante hinausgeht
          if (tooltipY + tooltipHeight > windowHeight) {
            // Bei vielen Datenpunkten ist sowohl die bottom als auch die top Variante nicht möglich
            // Daher hier top Variante nach unten korrigiert
            if (overlappingPoints > 1) {
              tooltipY = mouseY - tooltipHeight + 200;
            } else {
              tooltipY = mouseY - tooltipHeight - 15;
            }
          }
          tooltip.style("top", `${tooltipY}px`).style("left", `${tooltipX}px`);
        }
      }

      let overlappingPoints;

      svg
        .selectAll("circle")
        .on("mouseover", function (event, d) {
          // Cast d zu DataPoint
          const point = d as DataPoint;

          // Maus Icon ändern wenn Punkt klickbar ist
          d3.select(this).style(
            "cursor",
            handleDataPointClick && point?.name !== "Allgemein"
              ? "pointer"
              : "default"
          );

          overlappingPoints = getOverlappingPoints(d as DataPoint);
          let tooltipContent = "";

          // Tooltip Content in Abhängigkeit vom plotType und der Anzahl sich überlappender Datenpunkte
          if (overlappingPoints.length > 1) {
            if (plotType === "Scorecard") {
              tooltipContent = overlappingPoints
                .map(
                  (point) => `<strong>${point.name}</strong><br/>
            Mittlere Relevanz: ${point.mean_relevance.toFixed(2)}<br/>
            Mittlere Ausprägung: ${point.mean_development.toFixed(2)}<br/>
        `
                )
                .join("<br/><br/>");
            } else {
              tooltipContent = overlappingPoints
                .map(
                  (point) => `<strong>${point.name}</strong><br/>
                            Relevanz: ${point.mean_relevance.toFixed(2)}<br/>
                            Ausprägung: ${point.mean_development.toFixed(2)}
                        `
                )
                .join("<br/><br/>");
            }
          } else {
            if (plotType === "Scorecard") {
              tooltipContent = `<strong>${point.name}</strong><br/>
            Mittlere Relevanz: ${point.mean_relevance.toFixed(2)}<br/>
            Mittlere Ausprägung: ${point.mean_development.toFixed(2)}
        `;
            } else {
              tooltipContent = `<strong>${point.name}</strong><br/>
                            Relevanz: ${point.mean_relevance.toFixed(2)}<br/>
                            Ausprägung: ${point.mean_development.toFixed(2)}
                        `;
            }
          }
          tooltip
            .html(tooltipContent)
            .style("visibility", "visible")
            .style("z-index", "1500");

          updateTooltipPosition(event, overlappingPoints.length);
        })
        .on("mousemove", function (event) {
          tooltip
            .style("top", `${event.pageY - 10}px`)
            .style("left", `${event.pageX + 10}px`);
          updateTooltipPosition(event, overlappingPoints.length);
        })
        .on("mouseout", function () {
          tooltip.style("visibility", "hidden");
        })
        .on("click", function (event, d) {
          // Cast d zu DataPoint
          const point = d as DataPoint;
          if (handleDataPointClick && point.name !== "Allgemein")
            handleDataPointClick(point.name);
        });

      // Farben für die Legende
      const legendColors = [
        Colors.Yellow,
        Colors.MintGreen,
        Colors.LightGreen,
        Colors.DarkBlue,
        Colors.LightBlue,
        Colors.Red,
        Colors.Orange,
        Colors.Purple,
      ];

      // Objekt um zugewiesene Farben für Legende zu speichern
      const assignedColors: { [index: number]: string } = {};

      // Unterschiedliche Farben für Punkte hinzufügen
      svg.selectAll("circle").each(function (d, i) {
        const point = d as DataPoint;
        const overlappingPoints = getOverlappingPoints(point);
        const index = i;

        // Überprüfe ob eine der überlappenden Punkte schon eine zugewiesene Farbe hat
        let assignedColor = null;
        for (const overlap of overlappingPoints) {
          const overlapIndex = plotData.findIndex((p) => p === overlap);
          if (assignedColors[overlapIndex]) {
            assignedColor = assignedColors[overlapIndex]; // Wenn ja nutze dieselbe Farbe
            break;
          }
        }

        // Wenn nein, wähle die nächste Farbe aus legendColors aus
        if (!assignedColor) {
          assignedColor = legendColors[index % legendColors.length];
          assignedColors[index] = assignedColor;
        }

        // Fill Attribute mit ausgeählter Farbe
        d3.select(this).attr("fill", assignedColor);

        // Datenpunkt mit ausgewählter Farbe zu pdfData hinzufügen
        pdfData.push({
          ...point,
          color: assignedColor,
        });
      });

      //pdf Daten sortiren, erst nach Relevanz dann nach Ausprägung
      const sortedPdfData = pdfData.sort((a, b) => {
        if (b.mean_relevance !== a.mean_relevance) {
          return b.mean_relevance - a.mean_relevance;
        }
        return b.mean_development - a.mean_development;
      });

      // Tabelle hinzufügen
      const tableGroup = svg.append("g");

      // Define column headers and their relative widths (as a percentage of total width)
      const columns = [
        { id: "color", text: "Farbe", widthPercent: 10 },
        {
          id: "dimension",
          text: plotType === "Scorecard" ? "Dimension" : "Erfolgsfaktor",
          widthPercent: plotType === "Scorecard" ? 20 : 50,
        },
        {
          id: "relevance",
          text: plotType === "Scorecard" ? "Mittlere Relevanz" : "Relevanz",
          widthPercent: plotType === "Scorecard" ? 20 : 15,
        },
        {
          id: "development",
          text: plotType === "Scorecard" ? "Mittlere Ausprägung" : "Ausprägung",
          widthPercent: plotType === "Scorecard" ? 23 : 15,
        },
        {
          id: "difference",
          text: "Differenz",
          widthPercent: plotType === "Scorecard" ? 20 : 15,
        },
      ];

      const svgWidth = svg.node()?.getBoundingClientRect().width ?? 0;
      // Calculate x positions dynamically
      let cumulativeWidth = 0;
      const columnXPositions = columns.map((col) => {
        const colWidth = (col.widthPercent / 100) * svgWidth;
        const xPos = cumulativeWidth;
        cumulativeWidth += colWidth;
        return xPos;
      });

      // Add column headers dynamically based on calculated positions
      columns.forEach((col, i) => {
        tableGroup
          .append("text")
          .attr("x", columnXPositions[i])
          .attr("y", 0)
          .attr("font-family", fontFamily)
          .style("font-weight", "bold")
          .text(col.text);
      });

      // Gibt es Text der mehr als eine Zeile benötigt? Dann größeren Zeilenabstand verwenden
      const currentFactor = sortedPdfData.some((d) => d.name.length > 30)
        ? 2.15
        : 1.5;

      // Row für jeden Datenpunkt hinzufügen
      sortedPdfData.forEach((d, i) => {
        // Linebreak wenn Name mehr als maxCharsPerLine Zeichen enthält
        const maxCharsPerLine = 50;
        const curentYDistance = 45;
        const dimensionText = d.name;
        let wordBeginsIndex = 0;

        if (dimensionText.length > maxCharsPerLine) {
          for (let j = maxCharsPerLine; j >= 0; j--) {
            if (dimensionText[j] === " ") {
              wordBeginsIndex = j;
              break;
            }
          }
        }

        const firstLine =
          dimensionText.length > maxCharsPerLine
            ? dimensionText.slice(0, wordBeginsIndex)
            : dimensionText;
        const secondLine =
          dimensionText.length > maxCharsPerLine
            ? dimensionText.slice(wordBeginsIndex)
            : "";

        const tooltipLegendContent =
          plotType === "Scorecard"
            ? `<strong>${
                d.name
              }</strong><br/>Mittlere Relevanz: ${d.mean_relevance.toFixed(
                2
              )}<br/>Mittlere Ausprägung: ${d.mean_development.toFixed(2)}`
            : `<strong>${
                d.name
              }</strong><br/>Relevanz: ${d.mean_relevance.toFixed(
                2
              )}<br/>Ausprägung: ${d.mean_development.toFixed(2)}`;

        const dimensionName = d.name;

        const yPosition = i * currentFactor * 20 + curentYDistance;

        // Circle in the first column with Hover und click effect
        tableGroup
          .append("circle")
          .attr("cx", columnXPositions[0] + 20)
          .attr("cy", i * currentFactor * 20 + 40)
          .attr("r", 10)
          .attr("fill", d.color)
          .on("mouseover", (event) => {
            d3.select(event.currentTarget).style(
              "cursor",
              handleDataPointClick && d.name !== "Allgemein"
                ? "pointer"
                : "default"
            );

            tooltip
              .html(tooltipLegendContent)
              .style("left", event.pageX + 15 + "px")
              .style("top", event.pageY - 28 + "px")
              .style("visibility", "visible")
              .style("z-index", "1500");
          })
          .on("mouseout", () => tooltip.style("visibility", "hidden"))
          .on("click", () => {
            if (handleDataPointClick && dimensionName !== "Allgemein") {
              handleDataPointClick(dimensionName);
            }
          });

        // Dimension text
        tableGroup
          .append("text")
          .attr("x", columnXPositions[1])
          .attr("y", yPosition)
          .attr("font-family", fontFamily)
          .text(firstLine);

        if (secondLine) {
          tableGroup
            .append("text")
            .attr("x", columnXPositions[1])
            .attr("y", yPosition + 18)
            .attr("font-family", fontFamily)
            .text(secondLine);
        }

        // Relevance text
        tableGroup
          .append("text")
          .attr("x", columnXPositions[2])
          .attr("y", yPosition)
          .attr("font-family", fontFamily)
          .text(d.mean_relevance.toFixed(2));

        // Development text
        tableGroup
          .append("text")
          .attr("x", columnXPositions[3])
          .attr("y", yPosition)
          .attr("font-family", fontFamily)
          .text(d.mean_development.toFixed(2));

        // Difference text with colored background
        const difference = (d.mean_development - d.mean_relevance).toFixed(2);
        tableGroup
          .append("rect")
          .attr("x", columnXPositions[4])
          .attr("y", yPosition - 20)
          .attr("width", 75)
          .attr("height", 27)
          .attr("fill", `${getColorForDifference(parseFloat(difference))}`);
        tableGroup
          .append("text")
          .attr("x", columnXPositions[4] + 5)
          .attr("y", yPosition)
          .attr("font-family", fontFamily)
          .text(difference);
      });
      const tableBox = tableGroup.node()?.getBBox();

      if (tableBox) {
        const tableWidth = tableBox.width;
        const centerX = (innerWidth - tableWidth) / 2;
        tableGroup.attr(
          "transform",
          `translate(${centerX}, ${innerHeight + margin.top + 100})`
        );
      }

      if (plotData[0].name !== "") saveAsPDF();

      return () => {
        d3.select(svgElement).selectAll("*").remove();
        tooltip.remove();
      };
    }
    // eslint-disable-next-line
  }, [plotData]);

  const saveAsPDF = () => {
    // SVG zu String konvertieren
    const svgElement = svgRef.current;

    if (svgElement && plotType) {
      const clonedSvgElement = svgElement.cloneNode(true) as SVGElement;
      const preferredWidth = 900;
      const preferredHeight = 1272.6;

      // PDF soll vorgegebene Maße haben
      clonedSvgElement.setAttribute("width", preferredWidth.toString());
      clonedSvgElement.setAttribute("height", preferredHeight.toString());
      clonedSvgElement.setAttribute(
        "viewBox",
        `0 0 ${preferredWidth} ${preferredHeight}`
      );
      clonedSvgElement.setAttribute("preserveAspectRatio", "xMidYMid meet");
      clonedSvgElement.setAttribute("transform", `translate(${-100}, ${100})`);

      // clonedSVG in d3 selction konvertieren
      const d3ClonedSvg = d3.select(clonedSvgElement);

      // Header hinzufügen
      d3ClonedSvg
        .append("text")
        .attr("x", preferredWidth / 2 + 100) // +100 um transform translate von oben zu kompensieren
        .attr("y", -20)
        .attr("text-anchor", "middle")
        .attr("font-size", "24")
        .attr("font-family", fontFamily)
        .attr("fill", "black")
        .text(
          plotType === "Scorecard"
            ? "Scorecard Auswertung"
            : `Dimension ${plotType}`
        );

      const svgData = new XMLSerializer().serializeToString(
        clonedSvgElement as Node
      );

      if (setSVGStrings) {
        setSVGStrings((prevSVG) => {
          const svgExists = prevSVG.some((item) => item.name === plotType);
          if (!svgExists) {
            return [...prevSVG, { name: plotType, data: svgData }];
          }
          return prevSVG;
        });
      }
    }
  };

  return (
    <ScatterPlotContainer>
      <svg ref={svgRef}></svg>
    </ScatterPlotContainer>
  );
};

export default ScatterPlot;
