import { render } from "@react-email/render";
import ScoreCardEmail from "../components/Email/ScorecardEmailTemplate";

export const getScorecardLink = (customerId: string | undefined) => {
  if (customerId) return `${window.location.origin}/${customerId}`;
  else return window.location.origin;
};

export const downloadEmail = (
  customerId: string | undefined,
  customerName: string,
  password?: string
) => {
  render(
    <ScoreCardEmail link={getScorecardLink(customerId)} password={password} />,
    {
      pretty: true,
    }
  )
    .then((res) => {
      const recipientEmail = "";
      const emailSubject = "Link zur Scorecard - DHC GmbH";

      const emlContent = `To: ${recipientEmail}
Subject: ${emailSubject}
X-Unsent: 1
Content-Type: text/html; charset=UTF-8

${res}`;
      var data = new Blob([emlContent], { type: "message/rfc822" });
      const url = window.URL.createObjectURL(data);

      const link = document.createElement("a");
      link.download = `scorecard_email_template_${customerName}.eml`;
      link.href = url;
      link.click();

      // Clean up by revoking the object URL and removing the element
      window.URL.revokeObjectURL(url);
      link.remove();
    })
    .catch((err) => console.log(err));
};
