import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertSnackBar from "../../components/Alert/CustomAlert";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import { checkCustomerPassword } from "../../services/serviceCalls";

const PasswordPage = () => {
  const { setShowAlert, customerId, setCustomerVerified } = useAppContext();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [passwordIsFalse, setPasswordIsFalse] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (customerId) {
      checkCustomerPassword(customerId, data.get("password")?.toString() ?? "")
        .then((res) => {
          if (res.data === true) {
            setPasswordIsFalse(false);
            setCustomerVerified(true);
            navigate(`/general`);
          } else {
            setPasswordIsFalse(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowAlert(true);
        });
    } else {
      setShowAlert(true);
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={
              "https://arbeit-muss-schmecken.de/wp-content/uploads/2022/03/Group-Round.png"
            }
            width="70"
            height="70"
            alt="dhc_logo"
            style={{ margin: "15px" }}
          />
          <Typography component="h1" variant="h5">
            Passwort
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              error={passwordIsFalse}
              helperText={
                passwordIsFalse ? "Password stimmt nicht überein." : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Verifizieren
            </Button>
          </Box>
        </Box>
      </Container>
      <AlertSnackBar
        text="Etwas ist schiefgelaufen. Passwort konnte nicht verifiziert werden."
        type="error"
      />
    </>
  );
};

export default PasswordPage;
