import { FileInfo } from "../types/FileTypes";
import {
  Argumentation,
  DigitizationDegree,
  Dimension,
  FileComment,
  General,
  GeneralEvaluationDigitalValidation,
  GeneralEvaluationDimension,
  MasterData,
  Mean,
  OtherDocuments,
  Question,
  QuestionAnswer,
  Questionnaire,
  RelevanceDevelopment,
  ResultsEvaluationDimension,
  ScorecardData,
  ShortSelect,
  Successfactor,
} from "../types/ScorecardTypes";
export function isScorecardData(obj: any): obj is ScorecardData {
  // Type Guard for MasterData
  function isMasterData(masterData: any): masterData is MasterData {
    return (
      typeof masterData === "object" &&
      typeof masterData.customer_name === "string" &&
      typeof masterData.time_period_start === "number" &&
      typeof masterData.time_period_end === "number" &&
      typeof masterData.contact_person === "string" &&
      typeof masterData.comments === "string"
    );
  }
  // Type Guard for ResultsEvaluationDimension
  function isArgumentation(arg: any): arg is Argumentation {
    return (
      typeof arg === "object" &&
      typeof arg.value === "string" &&
      typeof arg.required === "boolean"
    );
  }
  function isShortSelect(select: any): select is ShortSelect {
    return (
      typeof select === "object" &&
      typeof select.value === "number" &&
      typeof select.required === "boolean"
    );
  }
  function isResultsEvaluationDimension(
    results: any
  ): results is ResultsEvaluationDimension {
    return (
      typeof results === "object" &&
      isArgumentation(results.argumentation) &&
      isShortSelect(results.relevance) &&
      isShortSelect(results.development)
    );
  }
  // Type Guard for GeneralEvaluationDimension
  function isGeneralEvaluationDimension(
    data: any
  ): data is GeneralEvaluationDimension {
    return (
      isArgumentation(data.self_understanding) &&
      isResultsEvaluationDimension(data)
    );
  }
  // Type Guard for Mean
  function isMean(mean: any): mean is Mean {
    return (
      typeof mean.relevance === "number" && typeof mean.development === "number"
    );
  }
  // Type Guard for Successfactor
  function isSuccessfactor(successfactor: any): successfactor is Successfactor {
    return (
      typeof successfactor === "object" &&
      typeof successfactor.title === "string" &&
      typeof successfactor.explanation === "string" &&
      Array.isArray(successfactor.measurements) &&
      successfactor.measurements.every(
        (measurement: any) => typeof measurement === "string"
      ) &&
      isResultsEvaluationDimension(successfactor.results)
    );
  }
  // Type Guard for Dimension
  function isDimension(dimension: any): dimension is Dimension {
    return (
      typeof dimension === "object" &&
      typeof dimension.dimension_title === "string" &&
      isGeneralEvaluationDimension(dimension.general_evaluation) &&
      Array.isArray(dimension.successfactors) &&
      dimension.successfactors.every(isSuccessfactor) &&
      isMean(dimension.mean) &&
      isMean(dimension.standard_deviation)
    );
  }
  // Type Guard for QuestionAnswer
  function isQuestionAnswer(
    questionAnswer: any
  ): questionAnswer is QuestionAnswer {
    return (
      typeof questionAnswer === "object" &&
      typeof questionAnswer.question === "string" &&
      typeof questionAnswer.answer === "string" &&
      (typeof questionAnswer.required === "boolean" ||
        typeof questionAnswer.required === "undefined")
    );
  }
  // Type Guard for DigitizationDegree
  function isDigitizationDegree(
    digitizationDegree: any
  ): digitizationDegree is DigitizationDegree {
    return (
      typeof digitizationDegree === "object" &&
      typeof digitizationDegree.question === "string" &&
      typeof digitizationDegree.value === "number" &&
      (typeof digitizationDegree.required === "boolean" ||
        typeof digitizationDegree.required === "undefined")
    );
  }
  // Type Guard for RelevanceDevelopment
  function isRelevanceDevelopment(
    relevanceDevelopment: any
  ): relevanceDevelopment is RelevanceDevelopment {
    return (
      typeof relevanceDevelopment === "object" &&
      typeof relevanceDevelopment.question === "string" &&
      typeof relevanceDevelopment.relevance === "number" &&
      typeof relevanceDevelopment.development === "number" &&
      (typeof relevanceDevelopment.required === "boolean" ||
        typeof relevanceDevelopment.required === "undefined")
    );
  }
  // Type Guard for GeneralEvaluationDigitalValidation
  function isGeneralEvaluationDigitalValidation(
    generalEvaluation: any
  ): generalEvaluation is GeneralEvaluationDigitalValidation {
    return (
      typeof generalEvaluation === "object" &&
      isRelevanceDevelopment(generalEvaluation.relevance_development) &&
      isDigitizationDegree(generalEvaluation.targeted_digitization_degree) &&
      isDigitizationDegree(generalEvaluation.current_digitization_degree) &&
      isQuestionAnswer(generalEvaluation.well_developed_areas) &&
      isQuestionAnswer(generalEvaluation.optimization_potential)
    );
  }
  // Type Guard for General
  function isGeneral(general: any): general is General {
    return (
      typeof general === "object" &&
      Array.isArray(general.self_understanding_digital_validation) &&
      general.self_understanding_digital_validation.every(isQuestionAnswer) &&
      isGeneralEvaluationDigitalValidation(
        general.general_evaluation_digital_validation
      )
    );
  }

  // Type Guard for FileInfo
  function isFileInfo(file: any): file is FileInfo {
    return (
      typeof file === "object" &&
      typeof file.filename === "string" &&
      typeof file.content_type === "string" &&
      typeof file.size === "number"
    );
  }
  // Type Guard for FileComment
  function isFileComment(data: any): data is FileComment {
    return (
      typeof data === "object" &&
      isFileInfo(data.file) &&
      typeof data.comment === "string"
    );
  }

  // Type Guard for Question
  function isQuestion(data: any): data is Question {
    return (
      typeof data === "object" &&
      typeof data.question === "string" &&
      (data.answer === "existing" || data.answer === "not_existing") &&
      typeof data.comment === "string" &&
      Array.isArray(data.files) &&
      data.files.every(isFileComment)
    );
  }

  // Type Guard for OtherDocuments
  function isOtherDocuments(data: any): data is OtherDocuments {
    return (
      typeof data === "object" &&
      typeof data.question === "string" &&
      typeof data.comment === "string" &&
      Array.isArray(data.files) &&
      data.files.every(isFileComment)
    );
  }

  // Type Guard for Questionnaire
  function isQuestionnaire(data: any): data is Questionnaire {
    return (
      typeof data === "object" &&
      Array.isArray(data.questions) &&
      data.questions.every(isQuestion) &&
      isOtherDocuments(data.other_documents)
    );
  }

  // Type Guard for ScorecardData
  return (
    obj !== null &&
    typeof obj === "object" &&
    isMasterData(obj.master_data) &&
    isGeneral(obj.general) &&
    Array.isArray(obj.dimensions) &&
    obj.dimensions.every(isDimension) &&
    isQuestionnaire(obj.questionnaire)
  );
}
