import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import CustomButton from "../CustomButton/CustomButton";

interface CustomerDataFormProps {
  customerId: string | undefined;
  customerName: string;
  setCustomerName: Dispatch<SetStateAction<string>>;
  dateStart: number | null;
  setDateStart: Dispatch<SetStateAction<number | null>>;
  dateEnd: number | null;
  setDateEnd: Dispatch<SetStateAction<number | null>>;
  contactPerson: string;
  setContactPerson: Dispatch<SetStateAction<string>>;
  comments: string;
  setComments: Dispatch<SetStateAction<string>>;
  author: string | undefined;
  setAuthor: Dispatch<SetStateAction<string | undefined>>;
  password: string | undefined;
  setPassword: Dispatch<SetStateAction<string | undefined>>;
  isFormValid: boolean;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
  buttonText: string;
  handleSubmit: () => void;
}

const CustomerDataForm: FC<CustomerDataFormProps> = ({
  customerId,
  customerName,
  setCustomerName,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  contactPerson,
  setContactPerson,
  comments,
  setComments,
  author,
  setAuthor,
  password,
  setPassword,
  isFormValid,
  setIsFormValid,
  buttonText,
  handleSubmit,
}) => {
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // password may not include '&' as this is disrupting the query when send to backend
  const passwordRegex = /^(?=.*[!@#$%^*]).{8,}$/;

  // Check if all required fields are filled
  useEffect(() => {
    const isValid =
      customerName.trim() !== "" &&
      dateStart !== null &&
      dateEnd !== null &&
      contactPerson.trim() !== "" &&
      (checked === false ||
        (checked === true &&
          password !== undefined &&
          passwordRegex.test(password)));
    setIsFormValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerName,
    dateStart,
    dateEnd,
    contactPerson,
    setIsFormValid,
    password,
    checked,
  ]);

  const handleDateChange = (
    newValue: Dayjs | null,
    setDate: Dispatch<SetStateAction<number | null>>
  ) => {
    const timestamp = dayjs(newValue).unix();
    setDate(timestamp);
  };

  // ensure start date is lower than end date and end date is higher than start date
  const DateRangeSelect = (
    label: string,
    date: number | null,
    rangeType: "start" | "end",
    setDate: Dispatch<SetStateAction<number | null>>
  ) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="DD.MM.YYYY"
        minDate={
          rangeType === "end"
            ? dateStart === null
              ? undefined
              : dayjs.unix(dateStart)
            : dayjs("1900-01-01") //default value of dtaepicker API
        }
        maxDate={
          rangeType === "start"
            ? dateEnd === null
              ? undefined
              : dayjs.unix(dateEnd)
            : dayjs("2099-12-31") //default value of dtaepicker API
        }
        disabled={customerId !== undefined}
        value={date === null ? null : dayjs.unix(date)}
        label={label}
        onChange={(newValue) => handleDateChange(newValue, setDate)}
        slotProps={{
          textField: {
            error: date === null,
            required: true,
            InputLabelProps: {
              shrink: true,
            },
          },
        }}
      />
    </LocalizationProvider>
  );

  return (
    <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
      <Typography variant="h6" gutterBottom>
        Kundendaten eingeben
      </Typography>
      <form noValidate autoComplete="off">
        <Box marginBottom={2}>
          <TextField
            fullWidth
            id="customer-name"
            label="Kundenname"
            required
            disabled={customerId !== undefined}
            variant="outlined"
            value={customerName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCustomerName(event.target.value);
            }}
            error={customerName.trim() === ""}
          />
        </Box>
        <Box marginBottom={2}>
          <Grid container spacing={2}>
            <Grid size={6}>
              {DateRangeSelect(
                "Zeitraum Start",
                dateStart,
                "start",
                setDateStart
              )}
            </Grid>
            <Grid size={6}>
              {DateRangeSelect("Zeitraum Ende", dateEnd, "end", setDateEnd)}
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={2}>
          <TextField
            fullWidth
            id="contact-person"
            label="Ansprechperson"
            variant="outlined"
            required
            disabled={customerId !== undefined}
            value={contactPerson}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setContactPerson(event.target.value);
            }}
            error={contactPerson.trim() === ""}
          />
        </Box>
        <Box marginBottom={2}>
          <TextField
            fullWidth
            id="comments"
            label="Anmerkungen"
            variant="outlined"
            multiline
            rows={3}
            disabled={customerId !== undefined}
            value={comments}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComments(event.target.value);
            }}
          />
        </Box>
        <Box marginBottom={2}>
          <TextField
            fullWidth
            id="author"
            label="Author"
            variant="outlined"
            required
            disabled={customerId !== undefined}
            value={author}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAuthor(event.target.value);
            }}
            error={author === undefined || author.trim() === ""}
            helperText={
              author === undefined || author.trim() === "" ? "Pflichtfeld" : ""
            }
          />
        </Box>
        <Box marginBottom={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  disabled={customerId !== undefined}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setChecked(event.target.checked);
                    if (event.target.checked === false) {
                      setPassword(undefined);
                    }
                  }}
                />
              }
              label="Link mit Passwort schützen"
            />
          </FormGroup>
          {checked ? (
            <TextField
              fullWidth
              id="password"
              label="Passwort"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              required
              disabled={customerId !== undefined}
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              error={password === undefined || !passwordRegex.test(password)}
              helperText={
                password === undefined || !passwordRegex.test(password)
                  ? "Das Passwort muss mind. 8 Zeichen lang sein und mind. ein Sonderzeichen !@#$%^* enthalten"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
        </Box>
        <Box marginTop={3}>
          <CustomButton
            buttonText={buttonText}
            onClick={handleSubmit}
            disabled={!isFormValid || customerId !== undefined}
          />
        </Box>
      </form>
    </Paper>
  );
};

export default CustomerDataForm;
