import { InputLabel, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { UpdateType } from "../../types/UpdateTypes";
import { CustomFormControl, StyledSelect } from "./CustomSelect.style";

interface ShortSelectProps {
  selectItems: string[];
  type: UpdateType;
  label: string;
  value: number;
  updateData: (value: number, type: UpdateType) => any;
  floatRight?: boolean;
  isValidated?: boolean;
  disabled?: boolean;
  required?: boolean | undefined;
}

const ShortSelect: FC<ShortSelectProps> = ({
  selectItems,
  type,
  label,
  value,
  updateData,
  floatRight,
  isValidated,
  disabled,
  required,
}) => {
  const [selectValue, setSelectvalue] = useState(value);

  const handleChange = (event: any) => {
    setSelectvalue(event.target.value);
    updateData(event.target.value, type);
  };

  return (
    <>
      <CustomFormControl floatRight={floatRight} variant="filled">
        <InputLabel style={{ width: "150px" }}>
          <em>{label}</em>
        </InputLabel>
        <StyledSelect
          isError={value === 0 && isValidated && required === true}
          error={value === 0 && isValidated && required === true}
          label={label}
          value={selectValue}
          onChange={handleChange}
          disabled={disabled}
        >
          <MenuItem value={0}>
            <em>Wert wählen</em>
          </MenuItem>
          {selectItems.map((elem, index) => (
            <MenuItem value={index + 1} key={elem}>
              {elem}
            </MenuItem>
          ))}
        </StyledSelect>
      </CustomFormControl>
    </>
  );
};

export default ShortSelect;
