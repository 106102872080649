import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useFetchTableData } from "../../hooks/useFetchTableData";
import { deleteCustomer } from "../../services/serviceCalls";
import { Roles } from "../../types/roleTypes";
import { useAppContext } from "../AppProvider/AppProvider";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";

interface EnhancedTableToolbarProps {
  selected: readonly string[];
  setSelected: Dispatch<SetStateAction<readonly string[]>>;
  searchValue: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = ({
  selected,
  setSelected,
  searchValue,
  handleSearch,
}) => {
  const { setShowAlert } = useAppContext();
  const { hasRole, getToken } = useKeycloak();
  const { fetchTableData } = useFetchTableData();

  const numSelected = selected.length;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 && hasRole([Roles.DELETE]) ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} ausgewählt
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Angelegte Scorecards
        </Typography>
      )}
      {numSelected > 0 && hasRole([Roles.DELETE]) ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              selected.forEach((item) => {
                const token = getToken();
                if (token) {
                  deleteCustomer(token, item)
                    .then((res) => {
                      fetchTableData();
                      setShowAlert(true);
                    })
                    .catch((err) => console.log(err));
                }
              });
              setSelected([]);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <FormControl sx={{ m: 1, minWidth: "30%" }}>
            <OutlinedInput
              id="filter"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              // defaultValue=""
              value={searchValue}
              placeholder="Filter nach Kundenname"
              size="small"
              onChange={handleSearch}
            />
          </FormControl>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
