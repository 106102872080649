import {
  Alert,
  Box,
  Collapse,
  Container,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import {
  downloadEmail,
  getScorecardLink,
} from "../../helpers/linkEmailHelpers";
import { useFetchTableData } from "../../hooks/useFetchTableData";
import { createCustomer } from "../../services/serviceCalls";
import { MasterData } from "../../types/ScorecardTypes";
import CopyToClipBoardField from "../CopyToClipboardField/CopyToClipboardField";
import CustomButton from "../CustomButton/CustomButton";
import CustomerDataForm from "../CustomerDataForm/CustomerDataForm";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";
import CircularLoading from "../Loading/Loading";
import { ModalContent, StyledBox } from "./CustomModal.style";

interface ExterneScorecardModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const ExternalScorecardModal: FC<ExterneScorecardModalProps> = ({
  open,
  setOpen,
}) => {
  const handleClose = (event: object, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };
  const { getToken, getUsername } = useKeycloak();
  const { fetchTableData } = useFetchTableData();

  const [customerId, setCustomerId] = useState<string>();

  const [customerName, setCustomerName] = useState<string>("");
  const [dateStart, setDateStart] = useState<number | null>(null);
  const [dateEnd, setDateEnd] = useState<number | null>(null);
  const [contactPerson, setContactPerson] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [author, setAuthor] = useState<string | undefined>(getUsername());

  const [password, setPassword] = useState<string | undefined>();

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "Scorecard konnte nicht erstellt werden. Möglicherweise exisitert kein Template in der Datenbank."
  );

  const resetForm = () => {
    setCustomerId(undefined);
    setCustomerName("");
    setDateStart(null);
    setDateEnd(null);
    setContactPerson("");
    setComments("");
    setAuthor(getUsername());
    setPassword(undefined);
    setIsFormValid(false);
    setIsLoading(false);
    setShowAlert(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setShowAlert(false);
    if (isFormValid) {
      const newCustomer: MasterData = {
        customer_name: customerName,
        time_period_start: dateStart ?? 0,
        time_period_end: dateEnd ?? 0,
        contact_person: contactPerson,
        comments: comments,
      };
      const token = getToken();
      if (token) {
        createCustomer(
          token,
          newCustomer,
          getUsername() ?? "Kein Nutzername",
          password ?? ""
        )
          .then((res) => {
            console.log(res.data);
            setIsLoading(false);
            setCustomerId(res.data);
            fetchTableData();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 409) {
              setErrorMessage("Kunde existiert bereits in der Datenbank");
            }
            setShowAlert(true);
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        setShowAlert(true);
        console.log("no valid token for request!");
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox>
        <ModalContent>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography
                id="extern_scorecard_modal_title"
                variant="h6"
                component="h2"
              >
                Externe Scorecard anlegen
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  resetForm();
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Typography id="extern_scorecard_modal_description" sx={{ mt: 2 }}>
            {
              <>
                Geben Sie hier die Kundendaten ein und klicken Sie auf{" "}
                <b>Link Generieren</b>.<br />
                Anschließend können Sie eine E-Mail im <b>.eml</b>-Format
                herunterladen, die Sie an den Kunden senden können.
              </>
            }
          </Typography>
          <Container maxWidth="sm">
            <CustomerDataForm
              customerId={customerId}
              customerName={customerName}
              setCustomerName={setCustomerName}
              dateStart={dateStart}
              setDateStart={setDateStart}
              dateEnd={dateEnd}
              setDateEnd={setDateEnd}
              contactPerson={contactPerson}
              setContactPerson={setContactPerson}
              comments={comments}
              setComments={setComments}
              author={author}
              setAuthor={setAuthor}
              password={password}
              setPassword={setPassword}
              isFormValid={isFormValid}
              setIsFormValid={setIsFormValid}
              buttonText="Link Generieren"
              handleSubmit={handleSubmit}
            />
            {isLoading ? <CircularLoading /> : null}
            <Collapse in={showAlert}>
              <Alert
                variant="filled"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ m: 2 }}
              >
                {errorMessage}
              </Alert>
            </Collapse>
            {customerId && (
              <CopyToClipBoardField
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                value={getScorecardLink(customerId)}
              />
            )}
          </Container>
          {customerId && (
            <CustomButton
              buttonText="Email für Kunden runterladen"
              onClick={() => downloadEmail(customerId, customerName, password)}
            />
          )}
        </ModalContent>
      </StyledBox>
    </Modal>
  );
};

export default ExternalScorecardModal;
