import { Button } from "@mui/material";
import { FC } from "react";
import { StyledButton } from "./CustomButton.style";

interface CustomButtonProps {
  buttonText: string;
}

const CustomButton: FC<
  CustomButtonProps & React.ComponentProps<typeof Button>
> = ({ buttonText, ...rest }) => {
  return (
    <StyledButton variant="contained" {...rest}>
      {buttonText}
    </StyledButton>
  );
};

export default CustomButton;
