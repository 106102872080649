import styled from "@emotion/styled";
import { Box, CircularProgress } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

export const CustomLoading = styled(CircularProgress)`
  color: ${Colors.MintGreen};
  padding: 3px;
`;

interface LoadingBoxProps {
  center?: boolean;
}

export const LoadingBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "center",
})<LoadingBoxProps>`
  text-align: center;
  ${(props) =>
    props.center &&
    ` 
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
    `};
`;
