import React, { createContext, FC, useContext, useState } from "react";
import { ScorecardData } from "../../types/ScorecardTypes";
import { Validation } from "../../types/UserTypes";

interface AppContextProps {
  customerId: string | null;
  setCustomerId: (id: string) => void;
  scorecardData: ScorecardData | undefined;
  setScorecardData: (data: ScorecardData) => void;
  validationList: Validation[];
  setValidationList: (data: Validation[]) => void;
  showAlert: boolean;
  setShowAlert: (alert: boolean) => void;
  submitted: boolean;
  setSubmitted: (status: boolean) => void;
  firstTime: boolean;
  setFirstTime: (status: boolean) => void;
  passwordRequired: boolean;
  setPasswordRequired: (status: boolean) => void;
  customerVerified: boolean;
  setCustomerVerified: (status: boolean) => void;
}

export const AppContext = createContext<AppContextProps>({
  customerId: null,
  setCustomerId: () => null,
  scorecardData: undefined,
  setScorecardData: () => null,
  validationList: [],
  setValidationList: () => null,
  showAlert: false,
  setShowAlert: () => null,
  submitted: false,
  setSubmitted: () => null,
  firstTime: false,
  setFirstTime: () => null,
  passwordRequired: false,
  setPasswordRequired: () => null,
  customerVerified: false,
  setCustomerVerified: () => null,
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [scorecardData, setScorecardData] = useState<ScorecardData>();
  const [validationList, setValidationList] = useState<Validation[]>([]);
  // handle Snackbar with error alert inside if apiCalls failed
  const [showAlert, setShowAlert] = useState<boolean>(false);
  // disable input fields when user has submitted data
  const [submitted, setSubmitted] = useState<boolean>(false);
  // first time user opened scorecard?
  const [firstTime, setFirstTime] = useState<boolean>(false);
  // is link password protected?
  const [passwordRequired, setPasswordRequired] = useState<boolean>(false);
  // is protected link verified?
  const [customerVerified, setCustomerVerified] = useState<boolean>(false);

  const [customerId, setCustomerIdState] = useState<string | null>(() => {
    return sessionStorage.getItem("customerId");
  });

  const setCustomerId = (id: string) => {
    setCustomerIdState(id);
    sessionStorage.setItem("customerId", id); // Persist ID in sessionStorage
  };

  const value = {
    customerId,
    setCustomerId,
    scorecardData,
    setScorecardData,
    validationList,
    setValidationList,
    showAlert,
    setShowAlert,
    setSubmitted,
    submitted,
    firstTime,
    setFirstTime,
    passwordRequired,
    setPasswordRequired,
    customerVerified,
    setCustomerVerified,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
