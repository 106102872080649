import { DimensionsMap } from "../constants/DimensionsMap";
import * as Colors from "../globalStyles/Colors";
import { Dimension, ScorecardData } from "../types/ScorecardTypes";
import { Validation } from "../types/UserTypes";
import { isStepCompleted } from "./checkCompletionHelpers";

export function getEntryFromDimension(
  dimension: string,
  Data: ScorecardData | null
): Dimension[] {
  return Data
    ? Data.dimensions.filter(function (entry: Dimension) {
        return entry.dimension_title === dimension;
      })
    : [];
}

export function getSuccessfactorByTitle(
  dimensionsEntry: Dimension[],
  dimension: string,
  title: string
) {
  const dimensionInfo: Dimension = dimensionsEntry.filter((dim) => {
    return dim.dimension_title === DimensionsMap.get(dimension);
  })[0];

  return dimensionInfo.successfactors.filter((elem) => {
    return elem.title === title;
  })[0];
}

export function containsString(
  validations: Validation[],
  searchString: string
): boolean {
  return validations.some((val) => val.dimension.includes(searchString));
}

export function isDimensionCompleted(
  validations: Validation[],
  dimension: string
): boolean {
  const validation = validations.find(
    (val) => val.dimension === dimension && val.isCompleted
  );
  return validation !== undefined;
}

export const isAllFieldsCompleted = (validations: Validation[]) => {
  return validations.every((val) => val.isCompleted === true);
};

export function saveValidationList(
  validationList: Validation[],
  currentDimension: string,
  setValidationList: (data: Validation[]) => void,
  scorecardData: ScorecardData
): void {
  if (scorecardData) {
    const isCompleted = isStepCompleted(currentDimension, scorecardData);
    const exisitingValidation = validationList.find(
      (val) => val.dimension === currentDimension
    );

    if (exisitingValidation !== undefined) {
      const updatedValidations = validationList.map((validation) =>
        validation.dimension === currentDimension
          ? { ...validation, isCompleted }
          : validation
      );
      setValidationList(updatedValidations);
    } else {
      const newValidation: Validation = {
        dimension: currentDimension,
        isCompleted: isCompleted,
      };
      setValidationList([...validationList, newValidation]);
    }
  }
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getColorForDifference = (difference: number) => {
  if (difference <= -1) {
    return Colors.RedTransparent("0.9");
  } else if (difference < 0 && difference > -1) {
    return Colors.RedTransparent("0.5");
  } else if (difference >= 0 && difference < 1) {
    return Colors.LightGreen;
  } else if (difference >= 1) {
    return Colors.LightGreenTransparent("0.5");
  }
};
