import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../AppProvider/AppProvider";
import CircularLoading from "../Loading/Loading";

const RedirectWithId: FC = () => {
  const { customer_id } = useParams();
  const navigate = useNavigate();
  const { customerId, setCustomerId, setCustomerVerified } = useAppContext();

  useEffect(() => {
    if (customer_id) {
      setCustomerVerified(customer_id === customerId);
      setCustomerId(customer_id);
      navigate("/general", { replace: true });
    }
  }, [customer_id, navigate, setCustomerId, setCustomerVerified, customerId]);

  return <CircularLoading center />;
};

export default RedirectWithId;
