import { FC } from "react";
import PrevNextButton from "../PrevNextButtons/PrevNextButton";

interface FooterProps {
  currentDimension: string;
  saveData: (...args: any[]) => any;
}

const Footer: FC<FooterProps> = ({ currentDimension, saveData }) => {
  return (
    <footer>
      <PrevNextButton currentDimension={currentDimension} saveData={saveData} />
    </footer>
  );
};

export default Footer;
