// for transparency use transparency-level of 50% or 70% -> opacity: 0.5 | 0.7
export const MintGreen = '#2BB198';
export const Yellow = '#D8B700';
export const DarkBlue = '#2C3E50';
export const LightBlue = '#85C1E9';
export const LightGreen = '#AAC317';
export const Black = '#000000';
export const White = '#FFFFFF';
export const LightGrey = '#DEDFDF';
export const BrightGrey = '#F4F4F4';
export const Grey = '#949497';
export const Red = '#B42318';
export const Orange = '#F28E2B';
export const Purple = '#B07AA1';
export const MintGreenTransparent = (transparentLevel: string) => `rgba(43, 177, 152, ${transparentLevel})`;
export const YellowTransparent = (transparentLevel: string) => `rgba(216, 183, 0, ${transparentLevel})`;
export const DarkBlueTransparent = (transparentLevel: string) => `rgba(44, 62, 80, ${transparentLevel})`;
export const LightBlueTransparent = (transparentLevel: string) => `rgba(133, 193, 233, ${transparentLevel})`;
export const LightGreenTransparent = (transparentLevel: string) => `rgba(170, 195, 23, ${transparentLevel})`;
export const BlackTransparent = (transparentLevel: string) => `rgba(0, 0, 0, ${transparentLevel})`;
export const RedTransparent = (transparentLevel: string) => `rgba(180, 35, 24, ${transparentLevel})`;

