import styled from "@emotion/styled";

export const StatePageWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  }
`;
