import { Box, Button, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as UploadSvg } from "../../assets/images/cloud_computing.svg";
import AlertSnackBar from "../../components/Alert/CustomAlert";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import CircularLoading from "../../components/Loading/Loading";
import UploadSection from "../../components/Questionnaire/UploadSection";
import * as Colors from "../../globalStyles/Colors";
import { mapFileToFileInfo, mergeLists } from "../../helpers/uploadHelpers";
import { useDirectToStartpage } from "../../hooks/useDirectToStartpage";
import {
  addCustomerQuestionnaire,
  uploadFilesToCustomerFolder,
} from "../../services/serviceCalls";
import { FileWComment } from "../../types/FileTypes";
import { Questionnaire } from "../../types/ScorecardTypes";

interface QuestionnairePageProps {
  currentDimension: string;
  isAdmin?: boolean;
}

const QuestionnairePage: FC<QuestionnairePageProps> = ({
  currentDimension,
  isAdmin,
}) => {
  const { scorecardData, customerId, setShowAlert, submitted } =
    useAppContext();

  const { customer_id } = useParams();
  useDirectToStartpage(scorecardData, isAdmin);

  const [allFilesWComment, setAllFilesWComment] = useState<FileWComment[][]>(
    scorecardData
      ? Array.from(
          // plus 1 to add an emtpy array for the 'other documents'
          { length: scorecardData.questionnaire.questions.length + 1 },
          () => []
        )
      : []
  );

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const questionnaireEntry = scorecardData
    ? scorecardData.questionnaire
    : ({} as Questionnaire);

  const updateAllFilesWComment = (
    fileComments: FileWComment[],
    index: number,
    concat?: boolean
  ) => {
    if (index >= 0 && index < allFilesWComment.length) {
      const exisitingFiles = allFilesWComment[index];
      allFilesWComment[index] = concat
        ? exisitingFiles.concat(fileComments)
        : fileComments;
      setAllFilesWComment(allFilesWComment);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let responseNum = 0;
    const allSelectedFiles = allFilesWComment.map((fc) =>
      fc.map((item) => item.file)
    );
    const nonEmptyListsCount = allSelectedFiles.filter(
      (list) => list.length > 0
    ).length;
    if (nonEmptyListsCount > 0 && scorecardData) {
      setShowLoading(true);
      allSelectedFiles.forEach((filesList, index) => {
        if (filesList.length > 0) {
          uploadFilesToCustomerFolder(
            filesList,
            scorecardData.master_data.customer_name,
            "Attachements"
          )
            .then((res) => {
              const fileInfo = res.data;

              // map the comments to the uploaded files
              const uploadedFilesWithComments = allFilesWComment[index].filter(
                (fileComments) =>
                  fileInfo.some((fI) => fI.filename === fileComments.file.name)
              );

              if (index === allFilesWComment.length - 1) {
                questionnaireEntry.other_documents.files = mergeLists(
                  questionnaireEntry.other_documents.files,
                  uploadedFilesWithComments.map((item) => ({
                    file: mapFileToFileInfo(item.file),
                    comment: item.comment,
                  })),
                  (item) => item.file.filename
                );
              } else {
                questionnaireEntry.questions[index].files = mergeLists(
                  questionnaireEntry.questions[index].files,
                  uploadedFilesWithComments.map((item) => ({
                    file: mapFileToFileInfo(item.file),
                    comment: item.comment,
                  })),
                  (item) => item.file.filename
                );
              }

              if (fileInfo.length === filesList.length) {
                // remove the selected files
                updateAllFilesWComment([], index);
              }

              responseNum += 1;
              if (responseNum === nonEmptyListsCount) {
                // once all file lists have been uploaded to the SharePoint
                saveDataInScorecard();
                setShowLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              setShowLoading(false);
              setShowAlert(true);
            });
        }
      });
    }
  };

  const saveDataInScorecard = () => {
    const cId = isAdmin ? customer_id : customerId;
    cId
      ? addCustomerQuestionnaire(questionnaireEntry, cId ?? "")
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            setShowAlert(true);
            console.log(error);
          })
      : setShowAlert(true);
  };

  const UploadButton = (
    <Box
      sx={{
        margin: "10px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        width: "25%",
      }}
    >
      <Button
        type="submit"
        variant="contained"
        tabIndex={-1}
        disabled={showLoading || submitted}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          textTransform: "none",
          width: "100%",
          minHeight: "80px",
          lineHeight: "normal",
          background: `${Colors.White}`,
          border: "3px solid transparent", // Transparent border to show the gradient
          borderRadius: "5px",
          backgroundClip: "padding-box, border-box",
          backgroundOrigin: "border-box",
          backgroundImage: `linear-gradient(${Colors.White}, ${Colors.White}), linear-gradient(to right, ${Colors.Yellow} 20%, ${Colors.MintGreen} 100%)`,
        }}
      >
        {showLoading ? (
          <CircularLoading />
        ) : (
          <>
            <div style={{ width: "40px", height: "30px" }}>
              <UploadSvg fill={`${Colors.MintGreen}`} />
            </div>
            Ausgewählte Dokumente hochladen
          </>
        )}
      </Button>
    </Box>
  );

  const indexOfOtherDocs = allFilesWComment.length - 1;

  return scorecardData === undefined ? (
    <CircularLoading center />
  ) : (
    <div className="App-container">
      {!isAdmin ? <Header currentDimension={currentDimension} /> : ""}
      <main key={currentDimension} className={isAdmin ? "admin" : ""}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px",
              paddingBottom: "15px",
            }}
          >
            <Typography variant="h6">
              Bitte geben Sie an, ob für die folgenden Themen eine SOP oder
              ähnliches Dokument vorliegt.
            </Typography>
            {UploadButton}
          </Box>
          {scorecardData.questionnaire.questions.map((question, index) => (
            // uploads for questions
            <UploadSection
              key={index}
              entry={question}
              index={index}
              type="question"
              updateAllFilesWComment={updateAllFilesWComment}
            />
          ))}
          {/* upload for other documents */}
          <UploadSection
            entry={scorecardData.questionnaire.other_documents}
            index={indexOfOtherDocs}
            type="otherDocs"
            updateAllFilesWComment={updateAllFilesWComment}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 16px",
              paddingBottom: "15px",
            }}
          >
            {UploadButton}
          </Box>
        </form>
        <AlertSnackBar
          text="Die Dokumente konnten nicht hochgeladen werden! Bitte versuchen Sie es
        nochmal."
          type="error"
        />
      </main>

      {!isAdmin ? (
        <Footer
          currentDimension={currentDimension}
          saveData={() => {
            saveDataInScorecard();
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default QuestionnairePage;
