import { FileInfo } from "../types/FileTypes";
import {
  Dimension,
  General,
  MasterData,
  Questionnaire,
  ScorecardData,
} from "../types/ScorecardTypes";
import { PlotData, SVGItem } from "../types/SVGDataTypes";
import { TableData } from "../types/TableDataTypes";
import { CustomerData } from "../types/UserTypes";
import apiConnection from "./apiConnection";

const headerAuthorization = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

// TODO: put API KEY in url or somewhere else
const api_token =
  process.env.REACT_API_KEY ||
  "8%a&36nz%yQEk0%6LcZfu7p2JkN%iKTE4rOo6&2IH9BfE7yOV8";

const headerApiToken = {
  headers: {
    apikey: api_token,
  },
};

// ######## SCORECARD ADMIN API CALLS ########
export async function getTemplate(token: string) {
  return await apiConnection.get<ScorecardData | boolean>(
    `/scorecard/admin/template`,
    headerAuthorization(token)
  );
}

export async function storeTemplate(
  token: string,
  body: ScorecardData | string
) {
  return await apiConnection.post<any>(
    `/scorecard/admin/template`,
    body,
    headerAuthorization(token)
  );
}

export async function getAllScorecardInfo(token: string) {
  return await apiConnection.get<TableData[]>(
    `/scorecard/admin`,
    headerAuthorization(token)
  );
}

// ######## SCORECARD API CALLS ########
export async function saveScorecard(body: ScorecardData, customerId: string) {
  return await apiConnection.post<void>(
    `/scorecard/${customerId}`,
    body,
    headerApiToken
  );
}

export async function getScorecardData(customerId: string) {
  return await apiConnection.get<ScorecardData>(
    `/scorecard/${customerId}`,
    headerApiToken
  );
}

export async function getPlotData(customerId: string) {
  return await apiConnection.get<PlotData>(
    `scorecard/plot_data/${customerId}`,
    headerApiToken
  );
}

// ######## CUSTOMER API CALLS ########
export async function getCustomerData(customerId: string) {
  return await apiConnection.get<CustomerData>(
    `/customer/data/${customerId}`,
    headerApiToken
  );
}

export async function updateCustomerData(
  customerId: string,
  body: CustomerData
) {
  return await apiConnection.post<void>(
    `/customer/update_data/${customerId}`,
    body,
    headerApiToken
  );
}

export async function checkCustomerPassword(
  customerId: string,
  password: string
) {
  return await apiConnection.post<boolean>(
    `/customer/password/${customerId}?pw=${password}`,
    "",
    headerApiToken
  );
}

// ######## CUSTOMER ADMIN API CALLS ########
export async function getCustomerIds(token: string) {
  return await apiConnection.get<string[]>(
    `/customer/admin/ids`,
    headerAuthorization(token)
  );
}

export async function createCustomer(
  token: string,
  newCustomer: MasterData,
  author: string,
  password: string
) {
  return await apiConnection.post<string>(
    `/customer/admin/create?author=${author}&pw=${password}`,
    newCustomer,
    headerAuthorization(token)
  );
}

export async function deleteCustomer(token: string, customerId: string) {
  return await apiConnection.delete<boolean>(
    `/customer/admin/${customerId}`,
    headerAuthorization(token)
  );
}

// ######## MASTER DATA API CALLS ########
export async function addCustomerMasterData(
  body: MasterData,
  customerId: string
) {
  return await apiConnection.post<void>(
    `/master_data/${customerId}`,
    body,
    headerApiToken
  );
}

// ######## GENERAL API CALLS ########
export async function addCustomerGeneral(body: General, customerId: string) {
  return await apiConnection.post<void>(
    `/general/${customerId}`,
    body,
    headerApiToken
  );
}

// ######## DIMENSIONS API CALLS ########
export async function addCustomerDimensions(
  body: Dimension[],
  customerId: string
) {
  return await apiConnection.post<void>(
    `/dimensions/${customerId}`,
    body,
    headerApiToken
  );
}

// ######## QUESTIONNAIRE API CALLS ########
export async function addCustomerQuestionnaire(
  body: Questionnaire,
  customerId: string
) {
  return await apiConnection.post<void>(
    `/questionnaire/${customerId}`,
    body,
    headerApiToken
  );
}

// ######## Upload/Download Data API CALLS ########
export async function uploadFilesToCustomerFolder(
  files: File[],
  customer_name: string,
  subfolder?: string
) {
  // create a new FormData object and append the file to it
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  const apiUrl = subfolder
    ? `sharepoint/upload_files_to_folder/${customer_name}?q_subfolder=${subfolder}`
    : `sharepoint/upload_files_to_folder/${customer_name}`;
  return await apiConnection.post<FileInfo[]>(apiUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      apikey: api_token,
    },
  });
}

// ##### SEND SVG DATA TO BACKEND ####

export async function save_svg_pdf(body: SVGItem[], customer_name: string) {
  return await apiConnection.post<void>(
    `/sharepoint/upload_svg_to_folder/${customer_name}`,
    body,
    headerApiToken
  );
}
