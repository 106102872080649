import { Typography } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";
import { StatePageWrapper } from "./StatePages.style";

const ErrorPage = () => {
  return (
    <StatePageWrapper>
      <Typography variant="h1" color={`${Colors.Red}`}>
        404
      </Typography>
      <Typography variant="h3" sx={{ mb: 3 }}>
        Diese Seite exisitert nicht!
      </Typography>

      <Typography sx={{ color: "text.secondary" }}>
        Leider konnten wir die von Ihnen gesuchte Seite nicht finden. Vielleicht
        haben Sie sich bei der URL vertippt? Überprüfen Sie bitte Ihre
        Rechtschreibung und angegebene ID.
      </Typography>
    </StatePageWrapper>
  );
};

export default ErrorPage;
