import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AdminProvider } from "./components/AdminProvider/AdminProvider";
import { AppProvider } from "./components/AppProvider/AppProvider";
import { KeycloakProvider } from "./components/KeycloakProvider/KeycloakProvider";
import ProtectedRoute from "./components/ProtectedElements/ProtectedRoute";
import RedirectWithId from "./components/RedirectWithId/RedirectWithId";
import { theme } from "./globalStyles/Theme";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminScorecard from "./pages/AdminScorecard/AdminScorecard";
import DimensionPage from "./pages/DimensionPage/DimensionPage";
import GeneralPage from "./pages/GeneralPage/GeneralPage";
import PasswordPage from "./pages/PasswordPage/PasswordPage";
import QuestionnairePage from "./pages/QuestionnairePage/QuestionnairePage";
import ErrorPage from "./pages/StatePages/ErrorPage";
import SubmitPage from "./pages/SubmitPage/SumbitPage";
import ScrollToTop from "./ScrollToTop";
import ResultsPage from "./pages/ResultsPage/ResultsPage";
import DimensionResultsPage from "./pages/ResultsPage/DimensionResultsPage";


function App() {
  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <KeycloakProvider>
          <AdminProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <AdminDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/scorecard/:customer_id"
                  element={
                    <ProtectedRoute>
                      <AdminScorecard />
                    </ProtectedRoute>
                  }
                />
                <Route path="/results/:customer_id"
                  element={<ProtectedRoute>
                    <ResultsPage />
                  </ProtectedRoute>}
                />
                <Route path="/results/:customer_id/:dimension"
                  element={<ProtectedRoute>
                    <DimensionResultsPage />
                  </ProtectedRoute>}
                />
              </Routes>
            </BrowserRouter>
          </AdminProvider>
        </KeycloakProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/:customer_id" element={<RedirectWithId />} />
            <Route
              path="/general"
              element={<GeneralPage currentDimension="general" />}
            />
            <Route path="/password" element={<PasswordPage />} />
            <Route
              path="/strategy"
              element={<DimensionPage currentDimension="strategy" />}
            />
            <Route
              path="/organization"
              element={<DimensionPage currentDimension="organization" />}
            />
            <Route
              path="/culture"
              element={<DimensionPage currentDimension="culture" />}
            />
            <Route
              path="/ressources"
              element={<DimensionPage currentDimension="ressources" />}
            />
            <Route
              path="/processlandscape"
              element={<DimensionPage currentDimension="processlandscape" />}
            />
            <Route
              path="/success"
              element={<DimensionPage currentDimension="success" />}
            />
            <Route
              path="/questionnaire"
              element={<QuestionnairePage currentDimension="questionnaire" />}
            />
            <Route
              path="/submit"
              element={<SubmitPage currentDimension="submit" />}
            />
            <Route path="/error" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
