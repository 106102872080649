import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, TextField } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import BreadcrumbBar from "../../components/Breadcrumb/BreadcrumbBar";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomerData from "../../components/CustomerData/CustomerData";
import CustomToolbar from "../../components/CustomToolbar/CustomToolbar";
import IconWithText from "../../components/Header/IconWithText";
import CircularLoading from "../../components/Loading/Loading";
import {
  useFetchScorecardData,
  useGetUser,
} from "../../hooks/useFetchUserData";
import { saveScorecard } from "../../services/serviceCalls";
import DimensionPage from "../DimensionPage/DimensionPage";
import GeneralPage from "../GeneralPage/GeneralPage";
import QuestionnairePage from "../QuestionnairePage/QuestionnairePage";
import {
  AdminScorecardPages,
  AdminScorecardWrapper,
  CustomerSection,
  ScorecardSection,
} from "./AdminScorecard.style";

const AdminScorecard: FC = () => {
  const { scorecardData } = useAppContext();

  const { customer_id } = useParams();

  const { fetchScorecardData } = useFetchScorecardData(customer_id);
  const { fetchUser } = useGetUser(customer_id);

  const [currentDimension, setCurrentDimension] = useState<string>("general");
  const [saved, setSaved] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const textFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    fetchScorecardData();
    fetchUser();
    // Text Feld soll fokussiert werden nachdem voriger Eventloop abgeschlossen ist
    setTimeout(() => {
      if (textFieldRef.current)
        textFieldRef.current.focus({ preventScroll: true });
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    if (scorecardData && customer_id) {
      saveScorecard(scorecardData, customer_id)
        .then((res) => {
          setSaved(true);
          setTimeout(() => {
            setShowFeedback(false);
          }, 5000);
          setShowFeedback(true);
        })
        .catch((err) => {
          setSaved(false);
          setShowFeedback(true);
        });
    }
  };

  return (
    <AdminScorecardWrapper>
      <header className="Admin-container">
        <CustomToolbar />
      </header>
      {scorecardData ? (
        <AdminScorecardPages>
          <CustomerSection id="excluded-section">
            {/* This textfiled is only for focus */}
            <TextField
              style={{ position: "absolute", opacity: 0, height: 0, width: 0 }}
              inputRef={textFieldRef}
            />
            <IconWithText text="Digital Validation Scorecard" />
            <CustomerData currentDimension="start" />
            <CustomButton
              onClick={handleButtonClick}
              buttonText="Scorecard speichern"
              style={{ marginBottom: "50px" }}
            />

            {showFeedback ? (
              <Alert
                severity={saved ? "success" : "error"}
                variant="filled"
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => setShowFeedback(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                style={{ alignItems: "center" }}
              >
                {saved
                  ? "Daten wurden erfolgreich abgeschickt!"
                  : "Daten konnten nicht gespeichert werden!"}
              </Alert>
            ) : null}
          </CustomerSection>
          <BreadcrumbBar
            currentDimension={currentDimension}
            setCurrentDimension={setCurrentDimension}
            isAdmin={true}
            customer_id={customer_id}
          />
          {currentDimension === "general" && (
            <ScorecardSection>
              <GeneralPage currentDimension="general" isAdmin={true} />
            </ScorecardSection>
          )}
          {currentDimension === "strategy" && (
            <ScorecardSection>
              <DimensionPage currentDimension="strategy" isAdmin={true} />
            </ScorecardSection>
          )}
          {currentDimension === "organization" && (
            <ScorecardSection>
              <DimensionPage currentDimension="organization" isAdmin={true} />
            </ScorecardSection>
          )}
          {currentDimension === "culture" && (
            <ScorecardSection>
              <DimensionPage currentDimension="culture" isAdmin={true} />
            </ScorecardSection>
          )}
          {currentDimension === "ressources" && (
            <ScorecardSection>
              <DimensionPage currentDimension="ressources" isAdmin={true} />
            </ScorecardSection>
          )}
          {currentDimension === "processlandscape" && (
            <ScorecardSection>
              <DimensionPage
                currentDimension="processlandscape"
                isAdmin={true}
              />
            </ScorecardSection>
          )}
          {currentDimension === "success" && (
            <ScorecardSection>
              <DimensionPage currentDimension="success" isAdmin={true} />
            </ScorecardSection>
          )}
          {currentDimension === "questionnaire" && (
            <ScorecardSection>
              <QuestionnairePage
                currentDimension="questionnaire"
                isAdmin={true}
              />
            </ScorecardSection>
          )}
        </AdminScorecardPages>
      ) : (
        <CircularLoading center />
      )}
    </AdminScorecardWrapper>
  );
};

export default AdminScorecard;
