import { FC } from "react";
import BreadcrumbBar from "../Breadcrumb/BreadcrumbBar";
import IconWithText from "./IconWithText";

interface HeaderProps {
  currentDimension?: string;
}

const Header: FC<HeaderProps> = ({ currentDimension }) => {
  return (
    <header className="App-header">
      <IconWithText text="Digital Validation Scorecard" />

      {currentDimension ? (
        <BreadcrumbBar currentDimension={currentDimension} />
      ) : null}
    </header>
  );
};

export default Header;
