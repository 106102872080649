import { Logout } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { Divider, ListItemIcon } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import logo from "../../assets/images/DHC_Logo.png";
import * as Colors from "../../globalStyles/Colors";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";

function CustomToolbar() {
  const { doLogout, getUsername, getEmail } = useKeycloak();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Logo = (
    <Typography
      variant="h5"
      noWrap
      sx={{
        mr: 2,
        display: "flex",
        fontFamily: "monospace",
        flexGrow: 0,
        fontWeight: 700,
        letterSpacing: ".3rem",
        color: "inherit",
        textDecoration: "none",
      }}
    >
      <img src={logo} alt="logo_img" height="55" />
    </Typography>
  );

  return (
    <AppBar position="static" sx={{ bgcolor: "white" }} tabIndex={-1}>
      <Container maxWidth="xl">
        <Toolbar disableGutters tabIndex={-1}>
          {Logo}

          {/* to display profile on right side */}
          <Box sx={{ flexGrow: 1 }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Profil">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                  <PersonIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box sx={{ px: 2, py: 1 }}>
                <Typography variant="body1" fontWeight="bold">
                  {getUsername()}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {getEmail()}
                </Typography>
              </Box>
              <Divider sx={{ borderStyle: "dotted", bgcolor: "lightgrey" }} />
              <MenuItem
                onClick={() => {
                  doLogout();
                }}
                sx={{ color: `${Colors.Red}` }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" sx={{ color: `${Colors.Red}` }} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default CustomToolbar;
