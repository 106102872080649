import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

export const InlineSelectWrapper = styled.div`
  display: inline-block;
  }
`;

export const Description = styled.div`
  padding: 10px 0px;
  margin-bottom: 10px;
  }
`;

interface DescriptionTitleProps {
  topSpace?: boolean;
}

export const DescriptionTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "topSpace",
})<DescriptionTitleProps>`
  font-weight: bold;
  ${(props) => props.topSpace && "margin-top: 10px"}
  }
`;

export const TextIconWrapper = styled(Typography)`
  color: grey;
  display: flex;
  align-items: center;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  height: auto;
  min-height: 39px;
  // margin: 12px 0;

  &.MuiFocusVisible {
    background-color: red;
  }
`;

export const CompletedIcon = styled(CheckCircleIcon)`
  color: ${Colors.MintGreen};
`;

export const MissingIcon = styled(ReportIcon)`
  color: ${Colors.Red};
`;

interface StyledAccordionProps {
  isGeneral?: boolean;
}

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== "isGeneral",
})<StyledAccordionProps>`
  background-color: ${Colors.LightGrey};
  box-shadow: none;
  &::after {
    ${(props) => props.isGeneral && "display: none"}
  }
`;
