import { FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { FC, useState } from "react";
import { QuestionWrapper } from "../../globalStyles/QuestionWrapper.style";
import { StyledDatePicker } from "./CustomDatePicker.style";

interface CustomDatePickerProps {
  label: string;
  value: number;
  updateData: (value: number) => void;
  isValidated: boolean;
  rangeType: "start" | "end";
  dateStart?: number;
  dateEnd?: number;
  disabled?: boolean;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  label,
  value,
  updateData,
  isValidated,
  rangeType,
  dateStart,
  dateEnd,
  disabled,
}) => {
  const [date, setDate] = useState(value);

  const handleChange = (newValue: Dayjs | null) => {
    const timestamp = dayjs(newValue).unix();
    setDate(timestamp);
    updateData(timestamp);
  };

  const isError = date === 0 && isValidated;

  return (
    <QuestionWrapper>
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledDatePicker
            isError={isError}
            format="DD.MM.YYYY"
            minDate={
              rangeType === "end"
                ? dateStart === undefined
                  ? undefined
                  : dayjs.unix(dateStart)
                : dayjs("1900-01-01") //default value of dtaepicker API
            }
            maxDate={
              rangeType === "start"
                ? dateEnd === undefined
                  ? undefined
                  : dayjs.unix(dateEnd)
                : dayjs("2099-12-31") //default value of dtaepicker API
            }
            value={date === 0 ? null : dayjs.unix(date)}
            label={label}
            onChange={(newValue) => handleChange(newValue)}
            slotProps={{
              textField: {
                variant: "filled",
                error: isError,
                required: true,
                InputLabelProps: {
                  shrink: true,
                },
              },
            }}
            disabled={disabled}
          />
        </LocalizationProvider>
      </FormControl>
    </QuestionWrapper>
  );
};

export default CustomDatePicker;
