import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useAppContext } from "../AppProvider/AppProvider";

interface AlertSnackBarProps {
  text: string;
  type: "success" | "warning" | "info" | "error";
}

const AlertSnackBar: React.FC<AlertSnackBarProps> = ({ text, type }) => {
  const { showAlert, setShowAlert } = useAppContext();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  return (
    <Snackbar
      open={showAlert}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackBar;
