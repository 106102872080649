import { FC } from "react";
import scorecard from "../../assets/images/Scorecard_Icon_oben.png";

interface IconWithTextProps {
  text: string;
}

const IconWithText: FC<IconWithTextProps> = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "2% 10%",
        fontSize: "calc(7px + 2vmin)",
      }}
    >
      <img
        src={scorecard}
        alt="scorecard_img"
        height="70"
        style={{ margin: "0 15px 20px 0" }}
      />
      <div
        style={{
          display: "block",
          fontSize: "calc(24px + 2vmin)",
          fontWeight: "bold",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default IconWithText;
