import styled from "@emotion/styled";
import { Button } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

export const StyledButton = styled(Button)`
  border-radius: 5px;
  color: ${Colors.Black};

  text-transform: none;
  background-image: linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.MintGreen} 100%
  );
`;
