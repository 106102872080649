import { FormControl, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import SelectQuestion from "../../components/CustomSelect/SelectQuestion";
import ShortSelect from "../../components/CustomSelect/ShortSelect";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MultiLineTextFieldQuestion from "../../components/TextFieldQuestion/MultiLineTextFieldQuestion";
import {
  currentDigitizationDegreeSelection,
  developmentSelection,
  relevanceSelection,
  targetedDigitizationDegreeSelection,
} from "../../constants/SelectionValues";
import { isStepCompleted } from "../../helpers/checkCompletionHelpers";

import { useLocation, useNavigate } from "react-router-dom";
import AlertSnackBar from "../../components/Alert/CustomAlert";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import CircularLoading from "../../components/Loading/Loading";
import {
  QuestionWrapper,
  StyledFormLabel,
} from "../../globalStyles/QuestionWrapper.style";
import { containsString } from "../../helpers/helpers";
import {
  useFetchScorecardData,
  useGetUser,
} from "../../hooks/useFetchUserData";
import {
  addCustomerGeneral,
  updateCustomerData,
} from "../../services/serviceCalls";
import {
  DigitizationDegree,
  General,
  QuestionAnswer,
  RelevanceDevelopment,
} from "../../types/ScorecardTypes";
import { UpdateDigitizationType, UpdateType } from "../../types/UpdateTypes";
import { InlineSelectWrapper } from "./GeneralPage.style";

interface GeneralPageProps {
  currentDimension: string;
  isAdmin?: boolean;
}

const GeneralPage: FC<GeneralPageProps> = ({ currentDimension, isAdmin }) => {
  const {
    customerId,
    scorecardData,
    setScorecardData,
    validationList,
    setShowAlert,
    submitted,
    firstTime,
    passwordRequired,
    customerVerified,
  } = useAppContext();

  const { fetchScorecardData } = useFetchScorecardData(customerId);
  const { fetchUser } = useGetUser(customerId);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!scorecardData) {
      fetchScorecardData();
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!isAdmin && passwordRequired && customerId) {
      if (!customerVerified) {
        navigate("/password");
      }
    }
  }, [passwordRequired, customerId, navigate, customerVerified, isAdmin]);

  const isValidated =
    containsString(validationList, currentDimension) || !firstTime;

  const generalEntry = scorecardData ? scorecardData.general : ({} as General);

  const updateSelfUnderstanding = (newState: any) => {
    if (scorecardData) {
      setScorecardData({
        ...scorecardData,
        general: {
          self_understanding_digital_validation: newState,
          general_evaluation_digital_validation:
            scorecardData.general.general_evaluation_digital_validation,
        },
      });
    }
  };

  const updateGeneralEvaluation = (newState: any) => {
    if (scorecardData) {
      setScorecardData({
        ...scorecardData,
        general: {
          self_understanding_digital_validation:
            scorecardData.general.self_understanding_digital_validation,
          general_evaluation_digital_validation: newState,
        },
      });
    }
  };

  const updateSelfUnderstandingData = (input: string, question?: string) => {
    const updatedEntry: QuestionAnswer = {
      question: question ? question : "",
      answer: input,
      required:
        generalEntry.self_understanding_digital_validation.find(
          (el) => el.question === question
        )?.required || true,
    };
    const newState: QuestionAnswer[] =
      generalEntry.self_understanding_digital_validation.map(
        (questionAnswer) => {
          if (questionAnswer.question === question) {
            return updatedEntry;
          }
          return questionAnswer;
        }
      );
    updateSelfUnderstanding(newState);
  };

  const updateWellDevelopedAreasData = (input: string, question?: string) => {
    const updatedEntry: QuestionAnswer = {
      question: question ? question : "",
      answer: input,
      required:
        generalEntry.general_evaluation_digital_validation[
          "well_developed_areas"
        ].required,
    };
    const newState = {
      ...generalEntry.general_evaluation_digital_validation,
      well_developed_areas: updatedEntry,
    };

    updateGeneralEvaluation(newState);
  };

  const updateOptimizationPotentialData = (
    input: string,
    question?: string
  ) => {
    const updatedEntry: QuestionAnswer = {
      question: question ? question : "",
      answer: input,
      required:
        generalEntry.general_evaluation_digital_validation[
          "optimization_potential"
        ].required,
    };
    const newState = {
      ...generalEntry.general_evaluation_digital_validation,
      optimization_potential: updatedEntry,
    };

    updateGeneralEvaluation(newState);
  };

  const updateRelevanceDevelopment = (value: number, type: UpdateType) => {
    const currentRelevanceDevelopment =
      generalEntry.general_evaluation_digital_validation.relevance_development;

    const updatedEntry: RelevanceDevelopment = {
      question:
        generalEntry.general_evaluation_digital_validation.relevance_development
          .question,
      relevance:
        type === "relevance" ? value : currentRelevanceDevelopment.relevance,
      development:
        type === "development"
          ? value
          : currentRelevanceDevelopment.development,
      required:
        generalEntry.general_evaluation_digital_validation[
          "relevance_development"
        ].required,
    };

    const newState = {
      ...generalEntry.general_evaluation_digital_validation,
      relevance_development: updatedEntry,
    };

    updateGeneralEvaluation(newState);
  };

  const updateDigitizationDegree = (
    question: string,
    value: number,
    type: UpdateDigitizationType
  ) => {
    const targetUpdatedEntry: DigitizationDegree = {
      question: question,
      value: value,
      required:
        generalEntry.general_evaluation_digital_validation[
          "targeted_digitization_degree"
        ].required,
    };

    const currentUpdatedEntry: DigitizationDegree = {
      question: question,
      value: value,
      required:
        generalEntry.general_evaluation_digital_validation[
          "current_digitization_degree"
        ].required,
    };

    const newState =
      type === "targeted_digitization_degree"
        ? {
            ...generalEntry.general_evaluation_digital_validation,
            targeted_digitization_degree: targetUpdatedEntry,
          }
        : {
            ...generalEntry.general_evaluation_digital_validation,
            current_digitization_degree: currentUpdatedEntry,
          };

    updateGeneralEvaluation(newState);
  };

  return scorecardData === undefined ? (
    <CircularLoading center />
  ) : (
    <div className="App-container">
      {!isAdmin ? <Header currentDimension={currentDimension} /> : ""}
      <main className={isAdmin ? "admin" : ""}>
        <FormControl required fullWidth style={{ marginTop: "20px" }}>
          <Typography variant="h6">
            Selbstverständnis des Themas Digitale Validierung
          </Typography>

          {generalEntry.self_understanding_digital_validation.map(
            (elem, index) => (
              <MultiLineTextFieldQuestion
                key={elem.question}
                question={elem.question}
                placeholder="Antwort"
                value={elem.answer}
                updateData={updateSelfUnderstandingData}
                isValidated={isValidated}
                disabled={submitted}
                maxRows={index === 2 ? 6 : 3}
                required={elem.required}
              />
            )
          )}

          <Typography variant="h6">
            Allgemeine Bewertung der digitalen Validierung im untersuchten
            Unternehmen
          </Typography>
          <QuestionWrapper>
            <StyledFormLabel
              isError={
                ((generalEntry.general_evaluation_digital_validation
                  .relevance_development.relevance === 0 &&
                  generalEntry.general_evaluation_digital_validation
                    .relevance_development.required === true) ||
                  (generalEntry.general_evaluation_digital_validation
                    .relevance_development.development === 0 &&
                    generalEntry.general_evaluation_digital_validation
                      .relevance_development.required === true)) &&
                isValidated
              }
              required={
                generalEntry.general_evaluation_digital_validation
                  .relevance_development.required === true
              }
            >
              {
                generalEntry.general_evaluation_digital_validation
                  .relevance_development.question
              }
            </StyledFormLabel>
            <InlineSelectWrapper>
              <ShortSelect
                selectItems={relevanceSelection}
                type="relevance"
                label="Relevanz"
                value={
                  generalEntry.general_evaluation_digital_validation
                    .relevance_development.relevance
                }
                updateData={updateRelevanceDevelopment}
                isValidated={isValidated}
                disabled={submitted}
                required={
                  generalEntry.general_evaluation_digital_validation
                    .relevance_development.required
                }
              />
              <ShortSelect
                selectItems={developmentSelection}
                type="development"
                label="Ausprägung"
                value={
                  generalEntry.general_evaluation_digital_validation
                    .relevance_development.development
                }
                updateData={updateRelevanceDevelopment}
                floatRight
                isValidated={isValidated}
                disabled={submitted}
              />
            </InlineSelectWrapper>
          </QuestionWrapper>
          <SelectQuestion
            question={
              generalEntry.general_evaluation_digital_validation
                .targeted_digitization_degree.question
            }
            type="targeted_digitization_degree"
            selectItems={targetedDigitizationDegreeSelection}
            label="Angestrebter Digitalisierungsgrad"
            value={
              generalEntry.general_evaluation_digital_validation
                .targeted_digitization_degree.value
            }
            updateData={updateDigitizationDegree}
            isValidated={isValidated}
            disabled={submitted}
            required={
              generalEntry.general_evaluation_digital_validation
                .targeted_digitization_degree.required
            }
          />
          <SelectQuestion
            question={
              generalEntry.general_evaluation_digital_validation
                .current_digitization_degree.question
            }
            type="current_digitization_degree"
            selectItems={currentDigitizationDegreeSelection}
            label="Aktueller Digitalisierungsgrad"
            value={
              generalEntry.general_evaluation_digital_validation
                .current_digitization_degree.value
            }
            updateData={updateDigitizationDegree}
            isValidated={isValidated}
            disabled={submitted}
            required={
              generalEntry.general_evaluation_digital_validation
                .current_digitization_degree.required
            }
          />
          <MultiLineTextFieldQuestion
            question={
              generalEntry.general_evaluation_digital_validation
                .well_developed_areas.question
            }
            placeholder="Antwort"
            value={
              generalEntry.general_evaluation_digital_validation
                .well_developed_areas.answer
            }
            updateData={updateWellDevelopedAreasData}
            isValidated={isValidated}
            disabled={submitted}
            required={
              generalEntry.general_evaluation_digital_validation
                .well_developed_areas.required
            }
          />
          <MultiLineTextFieldQuestion
            question={
              generalEntry.general_evaluation_digital_validation
                .optimization_potential.question
            }
            placeholder="Antwort"
            value={
              generalEntry.general_evaluation_digital_validation
                .optimization_potential.answer
            }
            updateData={updateOptimizationPotentialData}
            isValidated={isValidated}
            disabled={submitted}
            generalCompleted={isStepCompleted("general", scorecardData)}
            required={
              generalEntry.general_evaluation_digital_validation
                .optimization_potential.required
            }
          />
        </FormControl>
        <AlertSnackBar
          text="Die Daten konnten nicht gespeichert werden! Bitte versuchen Sie es
        nochmal."
          type="error"
        />
      </main>
      {!isAdmin ? (
        <Footer
          currentDimension={currentDimension}
          saveData={() =>
            customerId
              ? addCustomerGeneral(generalEntry, customerId ?? "")
                  .then((response) => {
                    console.log(response);
                    updateCustomerData(customerId, {
                      scorecard_finished: submitted,
                      first_time: false,
                    });
                  })
                  .catch((error) => {
                    setShowAlert(true);
                    console.log(error);
                  })
              : setShowAlert(true)
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GeneralPage;
