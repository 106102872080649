import styled from "@emotion/styled";
import { Box } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

export const AdminScorecardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.LightGrey};
`;

export const AdminScorecardPages = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  margin-top: 100px;
  margin-bottom: 50px;
  overflow: auto;
  scrollbar-width: thin;
  background-color: ${Colors.LightGrey};
`;

export const ScorecardSection = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const CustomerSection = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 1100px;
  margin-bottom: 50px;
`;
