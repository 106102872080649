import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";

interface CopyToClipBoardFieldProps {
  value: string;
  readonly?: boolean;
}

const CopyToClipBoardField: React.FC<
  CopyToClipBoardFieldProps & React.ComponentProps<typeof TextField>
> = ({ value, readonly = true, ...rest }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset tooltip after 2 seconds
    });
  };

  return (
    <TextField
      value={value}
      variant="outlined"
      InputProps={{
        readOnly: readonly,
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={copied ? "Kopiert!" : "In die Zwischenablage kopieren"}
              placement="top"
            >
              <IconButton onClick={handleCopyClick}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default CopyToClipBoardField;
