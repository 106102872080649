import styled from "@emotion/styled";
import * as Colors from "../../globalStyles/Colors";
import CustomButton from "../CustomButton/CustomButton";

interface NavigateButtonProps {
  floatRight?: boolean;
}

export const NavigateButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== "floatRight",
})<NavigateButtonProps>`
  margin: 5px;
  font-weight: bold;
  width: 100px;
  ${(props) => props.floatRight && "float: right;"}
  &:hover {
    color: ${Colors.White};
  }
  &:disabled {
    background-color: ${Colors.BlackTransparent("0.3")};
  }
`;
