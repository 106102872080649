import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import React, { FC } from "react";
import { Order } from "../../helpers/comparatorHelpers";
import { Roles } from "../../types/roleTypes";
import { TableData } from "../../types/TableDataTypes";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";

interface HeadCell {
  id: keyof TableData;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "customer_name",
    numeric: false,
    label: "Kundenname",
  },
  {
    id: "time_period_start",
    numeric: true,
    label: "Beginn des Zeitraums",
  },
  {
    id: "time_period_end",
    numeric: true,
    label: "Ende des Zeitraums",
  },
  {
    id: "contact_person",
    numeric: true,
    label: "Ansprechperson",
  },
  {
    id: "author",
    numeric: true,
    label: "Autor",
  },
  {
    id: "scorecard_finished",
    numeric: true,
    label: "Status",
  },
  {
    id: "id",
    numeric: true,
    label: "Email",
  },
  {
    id: "auswertung",
    numeric: true,
    label: "Auswertung",
  },
];

interface EnhancedTableHeadProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TableData
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead: FC<EnhancedTableHeadProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  const { hasRole } = useKeycloak();
  const createSortHandler =
    (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {hasRole([Roles.DELETE]) ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : null}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={
              hasRole([Roles.DELETE])
                ? headCell.numeric
                  ? "normal"
                  : "none"
                : "normal"
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
