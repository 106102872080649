import styled from "@emotion/styled";
import { Button } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

export const ResultsSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: start;
  height: 1500px;
  margin-bottom: 50px;
  background-color: ${Colors.LightGrey};
  position: absolute;
  top: 70px;
  z-index: 99;
`;

export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: center;
  background-color: ${Colors.LightGrey};
  overflow: hidden;
`;

export const ScatterSection = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 20px;
`;

export const ScatterSectionDimension = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 50px;
  visibility: hidden;
`;

export const BackButton = styled(Button)`
  border-radius: 5px;
  color: ${Colors.Black};
  font-weight: bold;
  width: 200px;
  text-transform: none;
  background-color: ${Colors.MintGreen};
  background-image: linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.MintGreen} 100%
  );

  &:hover {
    background-color: rgba(43, 177, 152, 0.8);
    color: white;
  }
  &:disabled {
    background-color: ${Colors.BlackTransparent("0.3")};
  }
`;
