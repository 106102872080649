import styled from "@emotion/styled";
import { FormControl, Select } from "@mui/material";

import * as Colors from "../../globalStyles/Colors";

interface CustomFormControlProps {
  floatRight?: boolean;
}

export const CustomFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "floatRight",
})<CustomFormControlProps>`
  width: 48%;
  ${(props) => props.floatRight && "float: right;"};
`;

interface StyledSelectProps {
  isError?: boolean;
}

export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== "isError",
})<StyledSelectProps>`
  border-radius: 5px 5px 0px 0px;
  background-color: ${Colors.White};
  border-bottom: 3px solid transparent;
  border-image: ${(props) =>
    props.isError
      ? `linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.Red} 100%
  )`
      : `linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.MintGreen} 100%
  )`};
  border-image-slice: 1;

  &.disabled {
    background-color: ${Colors.White};
  }
`;
