import styled from "@emotion/styled";
import * as Colors from "./Colors";

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.LightGrey};
  }
`;
