import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomToolbar from "../../components/CustomToolbar/CustomToolbar";
import IconWithText from "../../components/Header/IconWithText";
import CircularLoading from "../../components/Loading/Loading";
import ScatterPlot from "../../components/ScatterPlot/ScatterPlot";
import { getCustomerData, getPlotData } from "../../services/serviceCalls";
import { PlotData } from "../../types/SVGDataTypes";
import InfoPage from "../StatePages/InfoPage";
import {
  BackButton,
  ResultsSection,
  ResultsWrapper,
  ScatterSection,
} from "./ResultsPage.style";

interface DimensionPlotItem {
  name: string;
  mean_relevance: number;
  mean_development: number;
  attributes?: Record<any, any>;
}

const DimensionResultsPage = () => {
  const { customer_id, dimension } = useParams();
  const [plotData, setPlotData] = useState<PlotData>();
  const [dimensionPlotData, setDimensionPlotData] = useState<
    DimensionPlotItem[] | undefined
  >([]);
  const [scorecardFinished, setScorecardFinished] = useState<
    boolean | undefined
  >();
  const navigate = useNavigate();

  useEffect(() => {
    if (customer_id) {
      getCustomerData(customer_id)
        .then((res) => {
          setScorecardFinished(res.data.scorecard_finished);
          getPlotData(customer_id)
            .then((res) => {
              console.log(res.data);
              setPlotData(res.data);
              if (res.data) {
                const transformedplotData = Object.keys(res.data).map(
                  (key: string) => res.data[key as keyof PlotData]
                );
                const dimensionData = transformedplotData.find(
                  (dim) => dim.name === dimension
                );
                let dimensionPlotData;
                if (
                  dimensionData &&
                  dimensionData["development"] &&
                  dimensionData["relevance"] &&
                  dimensionData["attributes"]
                ) {
                  const developmentArray = dimensionData["development"];
                  const attributesArray = dimensionData["attributes"];
                  dimensionPlotData = dimensionData["relevance"].map(
                    (value, index) => {
                      return {
                        name: attributesArray[index].title,
                        mean_relevance: value,
                        mean_development: developmentArray[index],
                        attributes: dimensionData["attributes"],
                      };
                    }
                  );
                  setDimensionPlotData(dimensionPlotData);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          setScorecardFinished(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToResultsPage = () => {
    navigate(`/results/${customer_id}`);
  };

  return (
    <ResultsWrapper>
      <header className="Admin-container">
        <CustomToolbar />
      </header>
      {scorecardFinished === undefined ? (
        <CircularLoading />
      ) : scorecardFinished ? (
        <>
          <ResultsSection>
            {dimension && <IconWithText text={"Dimension " + dimension} />}
            <Container>
              {plotData === undefined || dimensionPlotData === undefined ? (
                <CircularLoading />
              ) : (
                <>
                  <BackButton onClick={backToResultsPage}>Zurück</BackButton>
                  <ScatterSection>
                    <ScatterPlot
                      plotData={dimensionPlotData}
                      plotType={dimension}
                    />
                  </ScatterSection>
                </>
              )}
            </Container>
          </ResultsSection>
        </>
      ) : (
        <InfoPage text="Auswertung für diese Scorecard exisitert noch nicht." />
      )}
    </ResultsWrapper>
  );
};

export default DimensionResultsPage;
