import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Typography } from "@mui/material";
import { FC } from "react";
import * as Colors from "../../globalStyles/Colors";
import { StatePageWrapper } from "./StatePages.style";

interface InfoPageProps {
  text: string;
}

const InfoPage: FC<InfoPageProps> = ({ text }) => {
  return (
    <StatePageWrapper>
      <ReportProblemOutlinedIcon
        style={{ color: `${Colors.Yellow}`, fontSize: "90px" }}
      />
      <Typography variant="h5" sx={{ mb: 3 }}>
        {text}
      </Typography>
    </StatePageWrapper>
  );
};

export default InfoPage;
