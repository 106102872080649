import axios from "axios";

const apiConnection = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  // timeout for requests
  timeout: 100000,
});

export default apiConnection;
