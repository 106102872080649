import { FileInfo } from "../types/FileTypes";

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function flattenListOfLists<T>(listOfLists: T[][]): T[] {
  return listOfLists.reduce((acc, curr) => acc.concat(curr), []);
}

export function mergeLists<T>(
  list1: T[],
  list2: T[],
  getComparisonValue: (item: T) => any
): T[] {
  const existingValues = new Set(list2.map(getComparisonValue));

  // Add items from list1 that are not in list2 based on comparison value
  const uniqueItemsFromList1 = list1.filter(
    (item1) => !existingValues.has(getComparisonValue(item1))
  );

  // Combine the unique items from list1 with list2
  return [...uniqueItemsFromList1, ...list2];
}

export function mapFileToFileInfo(file: File): FileInfo {
  return {
    filename: file.name,
    content_type: file.type,
    size: file.size,
  };
}
