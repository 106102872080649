import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers";
import * as Colors from "../../globalStyles/Colors";

interface StyledDatePickerProps {
  isError?: boolean;
}

export const StyledDatePicker = styled(DatePicker, {
  shouldForwardProp: (prop) => prop !== "isError",
})<StyledDatePickerProps>`
  border-radius: 5px 5px 0px 0px;
  background-color: ${Colors.White};
  border-bottom: 3px solid transparent;
  border-image: ${(props) =>
    props.isError
      ? `linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.Red} 100%
  )`
      : `linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.MintGreen} 100%
  )`};
  border-image-slice: 1;

  // Disable default bottom-border
  & .MuiFilledInput-root::before {
    border-bottom: none;
  }
  & .MuiFilledInput-root::after {
    border-bottom: none;
  }
  & .MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none;
  }

  & .MuiFilledInput-root.Mui-disabled {
    background-color: ${Colors.White};
  }

  & .MuiFilledInput-root.Mui-disabled::before {
    border-bottom: none;
  }

  // overwrite default background with white
  & .MuiFilledInput-root {
    background-color: ${Colors.White};
  }
  & .MuiFilledInput-root:hover {
    background-color: ${Colors.White};
    border: none;
    // Reset on touch devices, it doesn't add specificity
    @media (hover: none) : {
      background-color: ${Colors.White};
    }
  }
  & .MuiFilledInput-root.Mui-focused {
    background-color: ${Colors.White};
  }
`;
