import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFetchTableData } from "../../hooks/useFetchTableData";
import { TableData } from "../../types/TableDataTypes";

interface AdminContextProps {
  tableData: TableData[] | undefined;
  setTableData: (data: TableData[]) => void;
  requireReloadAdmin: boolean;
  setRequireReloadAdmin: (
    required: boolean | ((prev: boolean) => boolean)
  ) => void;
}

export const AdminContext = createContext<AdminContextProps>({
  tableData: undefined,
  setTableData: () => null,
  requireReloadAdmin: false,
  setRequireReloadAdmin: () => null,
});

export const useAdminContext = () => useContext(AdminContext);

export const AdminProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tableData, setTableData] = useState<TableData[]>();
  // state variable to trigger useEffect when needing to rerender Admin data (e.g. tableData)
  const [requireReloadAdmin, setRequireReloadAdmin] = useState<boolean>(false);

  const { fetchTableData } = useFetchTableData();

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    tableData,
    setTableData,
    requireReloadAdmin,
    setRequireReloadAdmin,
  };

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
