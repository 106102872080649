import styled from "@emotion/styled";
import { Box } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

export const StyledBox = styled(Box)`
  background-color: ${Colors.White};
  margin: 0 auto;
  width: 70%;
  display: table;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border: 1px solid;
  border-radius: 5px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 10px;
`;

export const ModalContent = styled(Box)`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;
