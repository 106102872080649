import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, TextField, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DimensionsMap } from "../../constants/DimensionsMap";
import {
  developmentSelection,
  GeneralAccordionDescription,
  RelevanceDevelopmentQuestionDimension,
  relevanceSelection,
} from "../../constants/SelectionValues";

import {
  QuestionWrapper,
  StyledFormLabel,
} from "../../globalStyles/QuestionWrapper.style";
import { isGeneralEvaluationCompleted } from "../../helpers/checkCompletionHelpers";
import { containsString } from "../../helpers/helpers";
import { addCustomerDimensions } from "../../services/serviceCalls";
import {
  Dimension,
  GeneralEvaluationDimension,
  ResultsEvaluationDimension,
} from "../../types/ScorecardTypes";
import { UpdateType } from "../../types/UpdateTypes";
import { useAppContext } from "../AppProvider/AppProvider";
import ShortSelect from "../CustomSelect/ShortSelect";
import MultiLineTextFieldQuestion from "../TextFieldQuestion/MultiLineTextFieldQuestion";
import {
  CompletedIcon,
  Description,
  DescriptionTitle,
  InlineSelectWrapper,
  MissingIcon,
  StyledAccordion,
  StyledAccordionSummary,
  TextIconWrapper,
} from "./CustomAccordion.style";

interface GeneralAccordionProps {
  dimension: string;
  expand: boolean;
  handleAccordionChange: (accordionTitle: string) => void;
  title: string;
  setReloadDimension?: React.Dispatch<React.SetStateAction<boolean>>;
  generalDimension?: Dimension;
  closedAccordion: string;
  isAdmin?: boolean;
}

const GeneralAccordion: FC<GeneralAccordionProps> = ({
  dimension,
  expand,
  handleAccordionChange,
  title,
  setReloadDimension,
  generalDimension,
  closedAccordion,
  isAdmin,
}) => {
  const {
    customerId,
    scorecardData,
    setScorecardData,
    validationList,
    setShowAlert,
    submitted,
    firstTime,
  } = useAppContext();

  // check flag for validating the fields when acoordion is being closed
  const [checkForCompletion, setCheckForCompletion] = useState<boolean>(false);

  // use a Ref for initial focus on the correct Accordion
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  // check for validating fields when coming back to this dimension page
  const isValidated = containsString(validationList, dimension);

  const dimensionsEntry = scorecardData ? scorecardData.dimensions : [];

  const currentDimension: Dimension = dimensionsEntry.filter((dim) => {
    return dim.dimension_title === DimensionsMap.get(dimension);
  })[0];

  const { customer_id } = useParams();

  useEffect(() => {
    if (expand && textFieldRef.current) {
      textFieldRef.current.focus({ preventScroll: true });
    }
  }, [expand]);

  const updateDimensionEvaluation = (
    updatedEntry: ResultsEvaluationDimension
  ) => {
    const newDimensions: Dimension[] = dimensionsEntry.map((dim) =>
      dim.dimension_title === DimensionsMap.get(dimension)
        ? ({
            ...dim,
            general_evaluation: updatedEntry,
          } as Dimension)
        : dim
    );

    if (scorecardData) {
      setScorecardData({
        ...scorecardData,
        dimensions: newDimensions,
      });
    }
  };

  const updateArgumentation = (value: string) => {
    const generalEvaluation = currentDimension.general_evaluation;

    const updatedEntry: ResultsEvaluationDimension = {
      ...generalEvaluation,
      argumentation: {
        value: value,
        required: generalEvaluation.argumentation.required,
      },
    };

    updateDimensionEvaluation(updatedEntry);
  };

  const updateSelfUnderstanding = (value: string) => {
    const generalEvaluation = currentDimension.general_evaluation;

    const updatedEntry: GeneralEvaluationDimension = {
      ...generalEvaluation,
      self_understanding: {
        value: value,
        required: generalEvaluation.self_understanding.required,
      },
    };

    updateDimensionEvaluation(updatedEntry);
  };

  const updateRelevanceDevelopment = (value: number, type: UpdateType) => {
    const generalEvaluation = currentDimension.general_evaluation;

    const updatedEntry: GeneralEvaluationDimension = {
      self_understanding: generalEvaluation.self_understanding,
      argumentation: generalEvaluation.argumentation,
      relevance:
        type === "relevance"
          ? { value: value, required: generalEvaluation.relevance.required }
          : {
              value: generalEvaluation.relevance.value,
              required: generalEvaluation.relevance.required,
            },
      development:
        type === "development"
          ? { value: value, required: generalEvaluation.development.required }
          : {
              value: generalEvaluation.development.value,
              required: generalEvaluation.development.required,
            },
    };

    updateDimensionEvaluation(updatedEntry);
  };

  const handleChange = () => {
    addCustomerDimensions(
      [currentDimension],
      isAdmin ? customer_id ?? "" : customerId ?? ""
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setShowAlert(true);
        console.log(error);
      });
    handleAccordionChange(title);
  };

  return (
    <StyledAccordion
      expanded={expand}
      onChange={() => handleChange()}
      isGeneral={true} //remove line above first accordion
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ display: "block" }}>
          <TextIconWrapper>
            {DimensionsMap.get(dimension)}

            {!firstTime ||
            isValidated ||
            checkForCompletion ||
            title === closedAccordion
              ? (() => {
                  if (!checkForCompletion) setCheckForCompletion(true);
                  return isGeneralEvaluationCompleted(
                    currentDimension.general_evaluation
                  ) ? (
                    <CompletedIcon sx={{ ml: 0.5 }} fontSize="small" />
                  ) : (
                    <MissingIcon sx={{ ml: 0.5 }} fontSize="small" />
                  );
                })()
              : ""}
          </TextIconWrapper>
          {/* This textfiled is only for focus */}
          <TextField
            style={{ position: "absolute", opacity: 0, height: 0, width: 0 }}
            inputRef={textFieldRef}
          />
          <Typography variant="h6">{"Allgemeine Bewertung"}</Typography>
        </div>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Description>
          <DescriptionTitle>Erläuterung</DescriptionTitle>
          <Typography>
            {GeneralAccordionDescription(DimensionsMap.get(dimension) ?? "")}
          </Typography>
        </Description>
        <QuestionWrapper>
          <MultiLineTextFieldQuestion
            question={`Was macht für Sie die Dimension ${DimensionsMap.get(
              dimension
            )} aus?`}
            placeholder="Selbstverständnis der Dimension"
            value={
              currentDimension.general_evaluation.self_understanding.value
                ? currentDimension.general_evaluation.self_understanding.value
                : ""
            }
            updateData={updateSelfUnderstanding}
            isValidated={checkForCompletion || isValidated}
            disabled={submitted}
            required={
              currentDimension.general_evaluation.self_understanding.required
            }
          />
          <StyledFormLabel
            isError={
              ((currentDimension.general_evaluation.relevance.value === 0 &&
                currentDimension.general_evaluation.relevance.required ===
                  true) ||
                (currentDimension.general_evaluation.development.value === 0 &&
                  currentDimension.general_evaluation.development.required ===
                    true)) &&
              (checkForCompletion || isValidated)
            }
            required={
              currentDimension.general_evaluation.relevance.required ||
              currentDimension.general_evaluation.development.required
            }
          >
            {RelevanceDevelopmentQuestionDimension(
              DimensionsMap.get(dimension) ?? ""
            )}
          </StyledFormLabel>
          <InlineSelectWrapper>
            <ShortSelect
              selectItems={relevanceSelection}
              type="relevance"
              label="Relevanz"
              value={currentDimension.general_evaluation.relevance.value}
              updateData={updateRelevanceDevelopment}
              isValidated={checkForCompletion || isValidated}
              disabled={submitted}
              required={currentDimension.general_evaluation.relevance.required}
            />
            <ShortSelect
              selectItems={developmentSelection}
              type="development"
              label="Ausprägung"
              value={currentDimension.general_evaluation.relevance.value}
              updateData={updateRelevanceDevelopment}
              isValidated={checkForCompletion || isValidated}
              disabled={submitted}
              floatRight
              required={currentDimension.general_evaluation.relevance.required}
            />
          </InlineSelectWrapper>
        </QuestionWrapper>
        <MultiLineTextFieldQuestion
          question="Begründung"
          placeholder="Begründung"
          value={
            currentDimension.general_evaluation.argumentation.value
              ? currentDimension.general_evaluation.argumentation.value
              : ""
          }
          updateData={updateArgumentation}
          isValidated={checkForCompletion || isValidated}
          disabled={submitted}
          setReloadDimension={setReloadDimension}
          generalEvaluation={currentDimension.general_evaluation}
          setCheckForCompletion={setCheckForCompletion}
          currentDimension={currentDimension}
          isAdmin={isAdmin}
          required={currentDimension.general_evaluation.argumentation.required}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default GeneralAccordion;
