import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FC, useState } from "react";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";
import ExternalScorecardModal from "../Modals/ExternalScorecardModal";
import InternalScorecardModal from "../Modals/InternalScorecardModal";
import TemplateModal from "../Modals/TemplateModal";
import CustomCard from "./CustomCard";

const CreateScorecardCards: FC = () => {
  const { hasRole } = useKeycloak();
  const admin = hasRole(["scorecard-template"]);

  const [modals, setModals] = useState({
    external: false,
    internal: false,
    template: false,
  });

  const handleOpenModal = (modalType: "external" | "internal" | "template") => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: true,
    }));
  };

  const handleOpenExternal = () => handleOpenModal("external");
  const handleOpenInternal = () => handleOpenModal("internal");
  const handleOpenTemplate = () => handleOpenModal("template");

  return (
    <>
      <Box sx={{ pt: 5 }}>
        <Grid container spacing={2}>
          <Grid
            size={{ xs: 12, sm: 6, md: admin ? 4 : 6 }}
            container
            justifyContent="center"
          >
            <CustomCard
              title="Externe Scorecard"
              description="Erstellen eines Links zu einer Scorecard, welcher direkt per E-Mail
                  an den Kunden gesendet werden kann."
              buttonText="Erstelle Scorecard"
              handleButtonClick={handleOpenExternal}
              icon={<AddIcon />}
            />
          </Grid>
          <Grid
            size={{ xs: 12, sm: 6, md: admin ? 4 : 6 }}
            container
            justifyContent="center"
          >
            <CustomCard
              title="Interne Scorecard"
              description="Erstellen einer neuen Scorecard, direkt beim Kunden."
              buttonText="Erstelle Scorecard"
              handleButtonClick={handleOpenInternal}
              icon={<AddIcon />}
            />
          </Grid>
          {admin ? (
            <Grid
              size={{ xs: 12, md: admin ? 4 : 6 }}
              container
              justifyContent="center"
            >
              <CustomCard
                title="Template"
                description="Scorecard-Template bearbeiten. Die Änderung des Templates kann schwerwiegende Folgen für bereits bestehende Scorecards haben."
                buttonText="Bearbeite Template"
                handleButtonClick={handleOpenTemplate}
                icon={<EditIcon />}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <ExternalScorecardModal
        open={modals.external}
        setOpen={(isOpen: boolean) =>
          setModals((prevState) => ({ ...prevState, external: isOpen }))
        }
      />
      <InternalScorecardModal
        open={modals.internal}
        setOpen={(isOpen: boolean) =>
          setModals((prevState) => ({ ...prevState, internal: isOpen }))
        }
      />
      <TemplateModal
        open={modals.template}
        setOpen={(isOpen: boolean) =>
          setModals((prevState) => ({ ...prevState, template: isOpen }))
        }
      />
    </>
  );
};

export default CreateScorecardCards;
