import { FormControl, Typography } from "@mui/material";
import { FC } from "react";
import SuccessfactorAccordion from "../../components/CustomAccordion/SuccessfactorAccordion";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { DimensionsMap } from "../../constants/DimensionsMap";
import {
  isGeneralEvaluationCompleted,
  isStepCompleted,
  isSuccessfactorCompleted,
} from "../../helpers/checkCompletionHelpers";

import { useEffect, useRef, useState } from "react";
import AlertSnackBar from "../../components/Alert/CustomAlert";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import GeneralAccordion from "../../components/CustomAccordion/GeneralAccordion";
import CircularLoading from "../../components/Loading/Loading";
import * as Colors from "../../globalStyles/Colors";
import { useDirectToStartpage } from "../../hooks/useDirectToStartpage";
import {
  addCustomerDimensions,
  updateCustomerData,
} from "../../services/serviceCalls";
import { Dimension, Successfactor } from "../../types/ScorecardTypes";

interface DimensionPageProps {
  currentDimension: string;
  isAdmin?: boolean;
}

const DimensionPage: FC<DimensionPageProps> = ({
  currentDimension,
  isAdmin,
}) => {
  const { customerId, scorecardData, setShowAlert, submitted } =
    useAppContext();

  useDirectToStartpage(scorecardData, isAdmin);

  const dimensionsEntry = scorecardData ? scorecardData.dimensions : [];

  const dimension: Dimension = dimensionsEntry.filter((dim) => {
    return dim.dimension_title === DimensionsMap.get(currentDimension);
  })[0];

  // state management für Akkordionverhalten
  const [expandedAccordion, setExpandedAccordion] = useState<string>("");

  // Akkordion to be validated
  const [closedAccordion, setClosedAccordion] = useState<string>("");

  const textFieldRef = useRef<HTMLInputElement | null>(null);
  // state variable to trigger useEffect when a single Akkordion is finished and Tab key is pressed
  const [reloadDimension, setReloadDimension] = useState<boolean>(false);

  // function to toggle Accordion
  const handleAccordionChange = (accordionTitle: string) => {
    setExpandedAccordion((prev) => {
      setClosedAccordion(prev);
      if (prev === accordionTitle) {
        return "";
      } else {
        return accordionTitle;
      }
    });
  };

  // Without useEffect the pervious state of expandedAccordion persists, when navigating to another menu item
  useEffect(() => {
    if (dimension) {
      if (scorecardData && isStepCompleted(currentDimension, scorecardData)) {
        setExpandedAccordion("");
        if (textFieldRef.current)
          textFieldRef.current.focus({ preventScroll: true });
      } else {
        if (!isGeneralEvaluationCompleted(dimension.general_evaluation)) {
          setExpandedAccordion("Allgemeine Bewertung");
        } else {
          for (let i = 0; i < dimension.successfactors.length; i++) {
            if (!isSuccessfactorCompleted(dimension.successfactors[i])) {
              setExpandedAccordion(dimension.successfactors[i].title);
              break;
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDimension, reloadDimension]);

  return scorecardData === undefined ? (
    <CircularLoading center />
  ) : (
    <div className="App-container">
      {!isAdmin ? <Header currentDimension={currentDimension} /> : ""}
      <main key={currentDimension} className={isAdmin ? "admin" : ""}>
        <FormControl required fullWidth>
          <div>
            <GeneralAccordion
              dimension={currentDimension}
              expand={expandedAccordion === "Allgemeine Bewertung"}
              handleAccordionChange={handleAccordionChange}
              title={"Allgemeine Bewertung"}
              setReloadDimension={setReloadDimension}
              generalDimension={dimension}
              closedAccordion={closedAccordion}
              isAdmin={isAdmin}
            />
            {dimension ? (
              dimension.successfactors.map(
                (successfactor: Successfactor, index: number) => (
                  <SuccessfactorAccordion
                    dimension={currentDimension}
                    currentSuccessfactor={successfactor}
                    key={successfactor.title}
                    expand={successfactor.title === expandedAccordion}
                    handleAccordionChange={handleAccordionChange}
                    title={successfactor.title}
                    disabled={submitted}
                    setReloadDimension={setReloadDimension}
                    closedAccordion={closedAccordion}
                    isAdmin={isAdmin}
                  />
                )
              )
            ) : (
              <Typography style={{ color: `${Colors.Red}` }}>
                Keine Daten zu dieser Dimension gefunden
              </Typography>
            )}
          </div>
        </FormControl>
        <AlertSnackBar
          text="Die Daten konnten nicht gespeichert werden! Bitte versuchen Sie es
        nochmal."
          type="error"
        />
      </main>
      {!isAdmin ? (
        <Footer
          currentDimension={currentDimension}
          saveData={() =>
            customerId
              ? addCustomerDimensions([dimension], customerId ?? "")
                  .then((response) => {
                    console.log(response);
                    updateCustomerData(customerId, {
                      scorecard_finished: submitted,
                      first_time: false,
                    });
                  })
                  .catch((error) => {
                    setShowAlert(true);
                    console.log(error);
                  })
              : setShowAlert(true)
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DimensionPage;
