import { useEffect } from "react";
import { useAdminContext } from "../../components/AdminProvider/AdminProvider";
import AlertSnackBar from "../../components/Alert/CustomAlert";
import CreateScorecardCards from "../../components/CreateScorecardCards/CreateScorecardCards";
import CustomToolbar from "../../components/CustomToolbar/CustomToolbar";
import EnhancedTable from "../../components/DataTable/DataTable";
import CircularLoading from "../../components/Loading/Loading";
import { PageWrapper } from "../../globalStyles/PageWrapper.style";
import { useFetchTableData } from "../../hooks/useFetchTableData";

function AdminDashboard() {
  const { tableData } = useAdminContext();

  const { fetchTableData } = useFetchTableData();

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <header className="Admin-container">
        <CustomToolbar />
      </header>

      <main className="Admin-container">
        <CreateScorecardCards />
        {tableData === undefined ? (
          <CircularLoading center />
        ) : (
          <EnhancedTable data={tableData} />
        )}
        <AlertSnackBar text="Daten erfolgreich gelöscht!" type="success" />
      </main>
    </PageWrapper>
  );
}
export default AdminDashboard;
