import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

interface StyledTextFieldProps {
  isError?: boolean;
}

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isError",
})<StyledTextFieldProps>`
  border: none;
  & fieldset {
    border: none;
  }
  border-radius: 5px 5px 0px 0px;
  background-color: ${Colors.White};
  border-bottom: 3px solid transparent;
  border-image: ${(props) =>
    props.isError
      ? `linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.Red} 100%
  )`
      : `linear-gradient(
    to right,
    ${Colors.Yellow} 20%,
    ${Colors.MintGreen} 100%
  )`};
  border-image-slice: 1;
`;
