import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const ScatterPlotContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 1200px;
  width: 100%;
  overflow: auto;
  background-color: white;
  border-radius: 5px;
`;
