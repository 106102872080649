import { FormControl } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import MultiLineTextFieldQuestion from "../../components/TextFieldQuestion/MultiLineTextFieldQuestion";
import SingleTextFieldQuestion from "../../components/TextFieldQuestion/SingleLineTextFieldQuestion";
import { StyledFormLabel } from "../../globalStyles/QuestionWrapper.style";
import { containsString } from "../../helpers/helpers";
import {
  useFetchScorecardData,
  useGetUser,
} from "../../hooks/useFetchUserData";
import { MasterData } from "../../types/ScorecardTypes";
import AlertSnackBar from "../Alert/CustomAlert";
import { CustomerDataWrapper } from "./CustomerData.style";

interface CustomerDataProps {
  currentDimension: string;
}

const CustomerData: FC<CustomerDataProps> = ({ currentDimension }) => {
  const { scorecardData, setScorecardData, validationList, submitted } =
    useAppContext();

  const { customer_id } = useParams();

  const { fetchScorecardData } = useFetchScorecardData(customer_id);
  const { fetchUser } = useGetUser(customer_id);
  const location = useLocation();

  useEffect(() => {
    if (!scorecardData) {
      fetchScorecardData();
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isValidated = containsString(validationList, currentDimension);

  const customerEntry = scorecardData
    ? scorecardData.master_data
    : ({} as MasterData);

  const updateMasterData = (updatedEntry: MasterData) => {
    if (scorecardData) {
      setScorecardData({
        ...scorecardData,
        master_data: updatedEntry,
      });
    }
  };

  const updateCustomerName = (newValue: string) => {
    const updatedCustomerEntry: MasterData = {
      ...customerEntry,
      customer_name: newValue,
    };
    updateMasterData(updatedCustomerEntry);
  };

  const updateCustomerTimePeriodStart = (newValue: number) => {
    const updatedCustomerEntry: MasterData = {
      ...customerEntry,
      time_period_start: newValue,
    };
    updateMasterData(updatedCustomerEntry);
  };

  const updateCustomerTimePeriodEnd = (newValue: number) => {
    const updatedCustomerEntry: MasterData = {
      ...customerEntry,
      time_period_end: newValue,
    };
    updateMasterData(updatedCustomerEntry);
  };

  const updateCustomerContact = (newValue: string) => {
    const updatedCustomerEntry: MasterData = {
      ...customerEntry,
      contact_person: newValue,
    };
    updateMasterData(updatedCustomerEntry);
  };

  const updateCustomerComments = (newValue: string) => {
    const updatedCustomerEntry: MasterData = {
      ...customerEntry,
      comments: newValue,
    };
    updateMasterData(updatedCustomerEntry);
  };

  return (
    <CustomerDataWrapper>
      <FormControl required>
        <SingleTextFieldQuestion
          key="Kundenname"
          question="Kundenname"
          placeholder="Kundenname"
          value={customerEntry.customer_name}
          updateData={updateCustomerName}
          isValidated={isValidated}
          disabled={submitted}
        />
        <StyledFormLabel
          isError={
            (customerEntry.time_period_start === 0 ||
              customerEntry.time_period_end === 0) &&
            isValidated
          }
        >
          Zeitraum
        </StyledFormLabel>
        <Grid container spacing={2}>
          <Grid size={6}>
            <CustomDatePicker
              label="von"
              value={customerEntry.time_period_start}
              updateData={updateCustomerTimePeriodStart}
              isValidated={isValidated}
              disabled={submitted}
              rangeType="start"
              dateEnd={customerEntry.time_period_end}
            />
          </Grid>
          <Grid size={6}>
            <CustomDatePicker
              label="bis"
              value={customerEntry.time_period_end}
              updateData={updateCustomerTimePeriodEnd}
              isValidated={isValidated}
              disabled={submitted}
              rangeType="end"
              dateStart={customerEntry.time_period_start}
            />
          </Grid>
        </Grid>
        <SingleTextFieldQuestion
          key="Ansprechpartner Kunde"
          question="Ansprechpartner Kunde"
          placeholder="Ansprechpartner Kunde"
          value={customerEntry.contact_person}
          updateData={updateCustomerContact}
          isValidated={isValidated}
          disabled={submitted}
        />
        <MultiLineTextFieldQuestion
          key="Anmerkungen"
          question="Anmerkungen"
          placeholder="Anmerkungen"
          value={customerEntry.comments}
          updateData={updateCustomerComments}
          isValidated={isValidated}
          disabled={submitted}
          required={false}
        />
      </FormControl>
      <AlertSnackBar
        text="Die Daten konnten nicht gespeichert werden! Bitte versuchen Sie es
        nochmal."
        type="error"
      />
    </CustomerDataWrapper>
  );
};

export default CustomerData;
