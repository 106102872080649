import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { ListItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FC, useState } from "react";
import * as Colors from "../../globalStyles/Colors";
import { formatBytes } from "../../helpers/uploadHelpers";
import { useAppContext } from "../AppProvider/AppProvider";
import { StyledTextField } from "../TextFieldQuestion/TextFieldQuestion.style";

interface DocumentItemProps {
  index: number;
  type: "uploaded" | "newfile";
  filename: string;
  filesize: number;
  icon: JSX.Element;
  comment: string;
  updateComment: (i: number, comment: string) => void;
}

const DocumentItem: FC<DocumentItemProps> = ({
  index,
  type,
  filename,
  filesize,
  icon,
  comment,
  updateComment,
}) => {
  const { submitted } = useAppContext();

  // need state for uploaded files to change comment
  const [documentComment, setDocumentComment] = useState<string>(comment);

  const handleFileCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newComment = event.target.value;
    if (type === "uploaded") {
      setDocumentComment(newComment);
    }
    updateComment(index, newComment);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <ListItem key={index} style={{ padding: 0 }}>
      <Grid
        container
        spacing={1}
        sx={{
          width: "100%",
          alignItems: "center",
          background: `${Colors.White}`,
          margin: "2px 0",
          borderRadius: "5px",
          padding: "8px 16px",
          height: "45px",
        }}
      >
        <Grid size={9}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <InsertDriveFileOutlinedIcon />
            <Typography style={{ marginLeft: "5px" }}>{filename}</Typography>
          </span>
        </Grid>
        <Grid size={2}>
          <Typography style={{ fontSize: "14px" }}>
            {formatBytes(filesize)}
          </Typography>
        </Grid>
        <Grid size={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {icon}
        </Grid>
      </Grid>
      <StyledTextField
        key={index}
        value={type === "uploaded" ? documentComment : comment}
        onChange={handleFileCommentChange}
        onKeyDown={handleKeyDown}
        placeholder="Dateispezifische Anmerkungen"
        variant="outlined"
        disabled={submitted}
        style={{
          display: "flex",
          backgroundColor: `${Colors.White}`,
          borderRadius: "5px",
          marginLeft: "10px",
          width: "80%",
        }}
        slotProps={{
          input: {
            style: {
              height: "42px",
            },
          },
        }}
      />
    </ListItem>
  );
};

export default DocumentItem;
