// src/KeycloakProvider.tsx
import Keycloak, { KeycloakTokenParsed } from "keycloak-js";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface KeycloakContextProps {
  keycloak: Keycloak | null;
  initialized: boolean;
  doLogin: () => Promise<void>;
  doLogout: () => Promise<void>;
  getToken: () => string | undefined;
  getTokenParsed: () => KeycloakTokenParsed | undefined;
  isLoggedIn: () => boolean;
  updateToken: (successCallback: (refreshed: boolean) => void) => void;
  getUsername: () => string | undefined;
  getEmail: () => string | undefined;
  hasRole: (roles: string[]) => boolean;
}

const KeycloakContext = createContext<KeycloakContextProps>({
  keycloak: null,
  initialized: false,
  doLogin: () => Promise.resolve(),
  doLogout: () => Promise.resolve(),
  getToken: () => undefined,
  getTokenParsed: () => undefined,
  isLoggedIn: () => false,
  updateToken: () => {},
  getUsername: () => undefined,
  getEmail: () => undefined,
  hasRole: () => false,
});

export const KeycloakProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const keycloakInstance = new Keycloak({
      url: process.env.REACT_APP_AUTH_URL || "https://auth.dhc-consulting.com/",
      realm: "scorecard",
      clientId: "scorecard_auth",
    });

    keycloakInstance
      .init({
        onLoad: "check-sso",
      })
      .then((authenticated) => {
        console.log("Keycloak initialized, authenticated:", authenticated);
        setKeycloak(keycloakInstance);
        setInitialized(true); // Always set initialized to true to avoid loading loop -> initialized is checkd in protected route
      })
      .catch((error) => {
        console.error("Keycloak initialization failed:", error);
        setInitialized(true); // Always set initialized to true to avoid loading loop -> initialized is checkd in protected route
      });
  }, []);

  const doLogin = () => keycloak?.login() ?? Promise.resolve();

  const doLogout = () => keycloak?.logout() ?? Promise.resolve();

  const getToken = () => keycloak?.token;

  const getTokenParsed = () => keycloak?.tokenParsed;

  const isLoggedIn = () => !!keycloak?.token;

  const updateToken = (successCallback: (refreshed: boolean) => void) =>
    keycloak
      ?.updateToken(5)
      .then((refreshed) => {
        successCallback(refreshed);
      })
      .catch((error) => {
        doLogin().catch((error) => {
          console.log(error);
        });
      });

  const getUsername = () => keycloak?.tokenParsed?.preferred_username;

  const getEmail = () => keycloak?.tokenParsed?.email;

  const hasRole = (roles: string[]) =>
    roles.some((role: string) => keycloak?.hasRealmRole(role) ?? false);

  return (
    <KeycloakContext.Provider
      value={{
        keycloak,
        initialized,
        doLogin,
        doLogout,
        getToken,
        getTokenParsed,
        isLoggedIn,
        updateToken,
        getUsername,
        getEmail,
        hasRole,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

export const useKeycloak = () => useContext(KeycloakContext);
