import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import CustomButton from "../CustomButton/CustomButton";

interface CustomCardProps {
  title: string;
  description: string;
  buttonText: string;
  handleButtonClick: () => void;
  icon: any;
}

const CustomCard: FC<CustomCardProps> = ({
  title,
  description,
  buttonText,
  handleButtonClick,
  icon,
}) => {
  return (
    <Card
      sx={{
        width: "20rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <CardContent sx={{ height: "100px" }}>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <Box sx={{ p: 2, pt: 0, textAlign: "center" }}>
        <CustomButton
          buttonText={buttonText}
          startIcon={icon}
          onClick={handleButtonClick}
        />
      </Box>
    </Card>
  );
};

export default CustomCard;
