import styled from "@emotion/styled";
import { FormLabel } from "@mui/material";
import * as Colors from "./Colors";

export const QuestionWrapper = styled.div`
  margin-bottom: 15px;
  display: grid;
`;

interface StyledFormLabelProps {
  isError?: boolean;
}
export const StyledFormLabel = styled(FormLabel, {
  shouldForwardProp: (prop) => prop !== "isError",
})<StyledFormLabelProps>`
  color: ${(props) => (props.isError ? Colors.Red : Colors.Black)};
  margin-bottom: 3px;
`;
