import { useNavigate } from "react-router-dom";
import { useAppContext } from "../components/AppProvider/AppProvider";
import { getCustomerData, getScorecardData } from "../services/serviceCalls";

export function useFetchScorecardData(customerId: string | undefined | null) {
  const { setScorecardData } = useAppContext();
  const navigate = useNavigate();

  const fetchScorecardData = () => {
    if (customerId) {
      getScorecardData(customerId)
        .then((res) => setScorecardData(res.data))
        .catch((err) => {
          console.log(err);
          navigate("/error");
        });
    }
  };

  return { fetchScorecardData };
}

export function useGetUser(customerId: string | undefined | null) {
  const { setSubmitted, setFirstTime, setPasswordRequired } = useAppContext();

  const fetchUser = () => {
    if (customerId) {
      getCustomerData(customerId)
        .then((res) => {
          setSubmitted(res.data.scorecard_finished);
          setFirstTime(res.data.first_time);
          setPasswordRequired(res.data.password_required ?? false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return { fetchUser };
}
