import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import {
  DimensionsMap,
  getPreviousAndFollowingKey,
} from "../../constants/DimensionsMap";
import { saveValidationList } from "../../helpers/helpers";
import { NavigateButton } from "./PrevNextButton.style";

interface PrevNextProps {
  currentDimension: string;
  saveData: (...args: any[]) => any;
}

const PrevNextButton: FC<PrevNextProps> = ({ currentDimension, saveData }) => {
  const navigate = useNavigate();

  const { validationList, setValidationList, scorecardData } = useAppContext();

  const adjacentDimension = getPreviousAndFollowingKey(
    DimensionsMap,
    currentDimension
  );

  const handleClick = (linkTo: string) => {
    // add  dimension to validationList or update
    if (scorecardData)
      saveValidationList(
        validationList,
        currentDimension,
        setValidationList,
        scorecardData
      );
    saveData();
    navigate(`/${linkTo}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    //check if Tab was pressed and interprete it as a click on the button

    if (event.key === "Tab") {
      event.preventDefault();
      handleClick(adjacentDimension.followingKey);
    }
  };

  const prevButton = (
    <NavigateButton
      disabled={currentDimension === "general"}
      onClick={() => handleClick(adjacentDimension.previousKey)}
      startIcon={<ArrowBackIosIcon />}
      tabIndex={-1}
      buttonText="Zurück"
    />
  );

  const nextButton = (
    <NavigateButton
      disabled={currentDimension === "submit"}
      onClick={() => handleClick(adjacentDimension.followingKey)}
      endIcon={<ArrowForwardIosIcon />}
      floatRight
      onKeyDown={handleKeyDown}
      buttonText="Weiter"
    />
  );

  return (
    <>
      {currentDimension === "general" ? null : prevButton}
      {currentDimension === "submit" ? null : nextButton}
    </>
  );
};

export default PrevNextButton;
