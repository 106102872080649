import { createTheme } from "@mui/material";
import * as Colors from "./Colors";

export const theme = createTheme({
  typography: {
    fontFamily: [
      "Segoe UI",
      "Roboto",
      '"Helvetica Neue"',
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: `${Colors.MintGreen}`,
    },
    secondary: {
      main: `${Colors.Yellow}`,
    },
    success: {
      main: `${Colors.MintGreen}`,
    },
    error: {
      main: `${Colors.Red}`,
    },
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          padding: "0 20px",
          minWidth: "max-content",
          height: "52px",
          backgroundColor: `${Colors.White}`,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          ":hover": {
            backgroundColor: `${Colors.White}`,
            borderRadius: "5px 5px 0 0",
          },
          ".Mui-disabled": {
            backgroundColor: `${Colors.White}`,
            borderBottom: "none",
          },
        },
        root: {
          "::before": {
            borderBottom: `${Colors.White}`,
          },
          "::after": {
            borderBottom: "none",
          },
          ".Mui-disabled": {
            backgroundColor: `${Colors.White}`,
            borderRadius: "5px 5px 0 0",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // style dividers of accordion
          "::before": {
            backgroundColor: `${Colors.Grey}`,
            height: "2px",
          },
          // set line before accordion
          "::after": {
            backgroundColor: `${Colors.Grey}`,
            height: "2px",
            position: "absolute",
            left: 0,
            top: "-1px",
            right: 0,
            content: '""',
            opacity: 1,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: "25px 0",
        },
        root: {
          // ":focus": {
          //   backgroundColor: `${Colors.LightGrey}`,
          // },
        },
      },
    },
  },
});
