import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import {
  QuestionWrapper,
  StyledFormLabel,
} from "../../globalStyles/QuestionWrapper.style";
import { UpdateDigitizationType } from "../../types/UpdateTypes";
import { StyledSelect } from "./CustomSelect.style";

interface SelectQuestionProps {
  question: string;
  type: UpdateDigitizationType;
  selectItems: string[];
  label: string;
  value: number;
  updateData: (
    question: string,
    value: number,
    type: UpdateDigitizationType
  ) => any;
  isValidated?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const SelectQuestion: FC<SelectQuestionProps> = ({
  question,
  type,
  selectItems,
  label,
  value,
  updateData,
  isValidated,
  disabled,
  required,
}) => {
  const [selectValue, setSelectvalue] = useState(value);

  const handleChange = (event: any) => {
    setSelectvalue(event.target.value);
    console.log(event.target.value);
    updateData(question, event.target.value, type);
  };

  const isError = value === 0 && isValidated && required === true;

  return (
    <QuestionWrapper key={type}>
      <StyledFormLabel isError={isError} required={required}>
        {question}
      </StyledFormLabel>
      <FormControl variant="filled">
        <InputLabel style={{ width: "300px" }}>
          <em>{label}</em>
        </InputLabel>
        <StyledSelect
          isError={isError}
          error={isError}
          label={label}
          value={selectValue}
          onChange={handleChange}
          disabled={disabled}
        >
          <MenuItem value={0}>
            <em>Wert wählen</em>
          </MenuItem>
          {selectItems.map((degree, index) => (
            <MenuItem value={index + 1} key={degree}>
              {degree}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </QuestionWrapper>
  );
};

export default SelectQuestion;
