import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import * as Colors from "../../globalStyles/Colors";
import { mergeLists } from "../../helpers/uploadHelpers";
import { FileWComment } from "../../types/FileTypes";
import {
  OtherDocuments,
  Question,
  QuestionnaireAnswer,
} from "../../types/ScorecardTypes";
import { useAppContext } from "../AppProvider/AppProvider";
import { StyledTextField } from "../TextFieldQuestion/TextFieldQuestion.style";
import DocumentItem from "./DocumentItem";

interface UploadSectionProps {
  entry: Question | OtherDocuments;
  index: number;
  type: "question" | "otherDocs";
  updateAllFilesWComment: (
    files: FileWComment[],
    index: number,
    concat?: boolean
  ) => void;
}

const UploadSection: FC<UploadSectionProps> = ({
  entry,
  index,
  type,
  updateAllFilesWComment,
}) => {
  const { submitted } = useAppContext();

  function isQuestion(obj: any): obj is Question {
    return "answer" in obj;
  }

  const [selectedAnswer, setSelectedAnswer] = useState<QuestionnaireAnswer>(
    type === "question" && isQuestion(entry) ? entry.answer : "existing"
  );
  const [selectedFiles, setSelectedFiles] = useState<FileWComment[] | null>(
    null
  );
  const [comment, setComment] = useState<string>(entry.comment || "");

  useEffect(() => {
    setSelectedFiles(null);
  }, [entry.files]);

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const answer = event.target.value as QuestionnaireAnswer;
    if (type === "question" && isQuestion(entry)) {
      entry.answer = answer;
    }
    if (answer === "not_existing") {
      setSelectedFiles(null);
      updateAllFilesWComment([], index);
    }
    setSelectedAnswer(answer);
  };

  const handleGeneralCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newComment = event.target.value;
    entry.comment = newComment;
    setComment(newComment);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newlySelectedFiles = event.target.files;
    if (newlySelectedFiles && newlySelectedFiles.length > 0) {
      const existingFilenames = new Set(
        entry.files.map((fileComment) => fileComment.file.filename)
      );

      const filteredFiles = Array.from(newlySelectedFiles).filter(
        (file) => !existingFilenames.has(file.name)
      );
      const newFileWComments = filteredFiles.map(
        (filteredFile) => ({ file: filteredFile, comment: "" } as FileWComment)
      );

      setSelectedFiles(
        selectedFiles && selectedFiles.length > 0
          ? mergeLists(
              selectedFiles,
              [...newFileWComments],
              (item) => item.file.name
            )
          : [...newFileWComments]
      );
      updateAllFilesWComment([...newFileWComments], index, true);
    }
  };

  const handleRemoveFile = (fileIndex: number) => {
    if (selectedFiles) {
      const updatedFiles = selectedFiles.filter((_, i) => i !== fileIndex);
      setSelectedFiles(updatedFiles);
      updateAllFilesWComment(updatedFiles, index);
    }
  };

  const updateSelectedFileCommentField = (i: number, comment: string) => {
    if (selectedFiles) {
      selectedFiles[i].comment = comment;
      setSelectedFiles([...selectedFiles]);
      updateAllFilesWComment([...selectedFiles], index);
    }
  };

  const updateFileCommentField = (i: number, comment: string) => {
    entry.files[i].comment = comment;
  };

  return (
    <Box sx={{ padding: "25px 16px", borderTop: `2px solid ${Colors.Grey}` }}>
      <Typography variant="h6">{entry.question}</Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: `${type === "otherDocs" ? "flex-end" : "normal"}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {type === "question" && (
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={selectedAnswer}
                onChange={(e) => handleAnswerChange(e)}
              >
                <FormControlLabel
                  value="not_existing"
                  control={<Radio />}
                  label="Nicht vorhanden"
                  disabled={entry.files.length > 0 || submitted}
                />
                <FormControlLabel
                  value="existing"
                  control={<Radio />}
                  label="Vorhanden"
                  disabled={entry.files.length > 0 || submitted}
                />
              </RadioGroup>
            </FormControl>
          )}
          {(!isQuestion(entry) ||
            (selectedAnswer === "existing" &&
              type === "question" &&
              isQuestion(entry))) && (
            <Button
              component="label"
              variant="contained"
              startIcon={
                <InsertDriveFileOutlinedIcon
                  sx={{ color: `${submitted ? Colors.Grey : Colors.Black}` }}
                />
              }
              disabled={submitted}
              style={{
                float: "inline-end",
                textTransform: "none",
                display: "inline-flex",
                alignItems: "center",
                background: `${Colors.MintGreenTransparent("0.2")}`,
              }}
            >
              Auswählen
              <Typography style={{ fontSize: "12px", marginLeft: "10px" }}>
                .pdf,.doc(x),.xls(x),.ppt(x),.zip
              </Typography>
              <input
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/zip"
                multiple
                hidden
                onChange={(e) => handleFileChange(e)}
              />
            </Button>
          )}
        </Box>
        <StyledTextField
          multiline
          maxRows={2}
          value={comment}
          onChange={handleGeneralCommentChange}
          placeholder="Allgemeine Anmerkungen"
          variant="outlined"
          disabled={submitted}
          style={{ display: "flex", marginTop: "10px", width: "100%" }}
        />
      </Box>

      {(entry.files.length > 0 || selectedFiles) && (
        <List>
          {entry.files.map((fileComment, i) => (
            <DocumentItem
              key={i}
              index={i}
              type="uploaded"
              filename={fileComment.file.filename}
              filesize={fileComment.file.size}
              icon={
                <IconButton disabled size="small">
                  <CheckCircleIcon fontSize="small" color="primary" />
                </IconButton>
              }
              comment={fileComment.comment}
              updateComment={updateFileCommentField}
            />
          ))}

          {selectedFiles &&
            selectedFiles.map((fileComment, i) => (
              <DocumentItem
                key={i}
                index={i}
                type="newfile"
                filename={fileComment.file.name}
                filesize={fileComment.file.size}
                icon={
                  <IconButton
                    onClick={() => handleRemoveFile(i)}
                    aria-label="delete"
                    size="small"
                  >
                    <HighlightOffIcon
                      fontSize="small"
                      style={{ color: `${Colors.Grey}` }}
                    />
                  </IconButton>
                }
                comment={fileComment.comment}
                updateComment={updateSelectedFileCommentField}
              />
            ))}
        </List>
      )}
    </Box>
  );
};

export default UploadSection;
