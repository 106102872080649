import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../components/AppProvider/AppProvider";
import { ScorecardData } from "../types/ScorecardTypes";

export function useDirectToStartpage(
  scorecardData: ScorecardData | undefined,
  isAdmin: boolean | undefined
) {
  const { customerId } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      if (scorecardData === undefined) {
        console.log("redirecting to startpage");
        navigate(`/${customerId}`);
      }
    }
  }, [navigate, scorecardData, customerId, isAdmin]);
}
