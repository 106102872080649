import { useAdminContext } from "../components/AdminProvider/AdminProvider";
import { useKeycloak } from "../components/KeycloakProvider/KeycloakProvider";
import { getAllScorecardInfo } from "../services/serviceCalls";

export function useFetchTableData() {
  const { setTableData } = useAdminContext();
  const { isLoggedIn, getToken } = useKeycloak();

  const fetchTableData = () => {
    const token = getToken();
    if (isLoggedIn() && token) {
      getAllScorecardInfo(token)
        .then((res) => {
          setTableData([...res.data]);
        })
        .catch((err) => console.log(err));
    }
  };

  return { fetchTableData };
}
//
