import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DimensionsMap,
  getPreviousAndFollowingKey,
} from "../../constants/DimensionsMap";
import * as Colors from "../../globalStyles/Colors";
import { isStepCompleted } from "../../helpers/checkCompletionHelpers";
import { containsString, saveValidationList } from "../../helpers/helpers";
import {
  addCustomerDimensions,
  addCustomerGeneral,
  addCustomerMasterData,
  addCustomerQuestionnaire,
  updateCustomerData,
} from "../../services/serviceCalls";
import { Dimension } from "../../types/ScorecardTypes";
import { useAppContext } from "../AppProvider/AppProvider";
import {
  BreadcrumbItem,
  BreadcrumbNav,
  CompletedIcon,
  MissingIcon,
  NavBox,
  NextIcon,
} from "./BreadcrumbBar.style";

interface BreadcrumbBarProps {
  currentDimension: string;
  setCurrentDimension?: (
    dimension: string | ((prev: string) => string)
  ) => void;
  isAdmin?: boolean;
  customer_id?: string | undefined;
}

const BreadcrumbBar: FC<BreadcrumbBarProps> = ({
  currentDimension,
  setCurrentDimension,
  isAdmin,
  customer_id,
}) => {
  const navigate = useNavigate();

  const {
    customerId,
    scorecardData,
    validationList,
    setShowAlert,
    firstTime,
    setValidationList,
    submitted,
  } = useAppContext();

  const [scrollable, setIsScrollable] = useState<boolean>(false);

  const breadcrumbRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // set if arrows in Breadcrumb bar are visible
    if (breadcrumbRef.current) {
      const { scrollWidth, clientWidth } = breadcrumbRef.current;
      setIsScrollable(scrollWidth > clientWidth);
    }
  }, []);

  useEffect(() => {
    // when current item in breadcrumb bar changes move scrollbar accordingly
    if (breadcrumbRef.current) {
      const breadcrumbContainer = breadcrumbRef.current;
      const breadcrumbItems = breadcrumbContainer.querySelectorAll("li");

      // Find the item with a button that matches the currentDimension
      breadcrumbItems.forEach((item) => {
        const button = item.querySelector("button");
        if (
          button &&
          button.innerHTML.includes(DimensionsMap.get(currentDimension) ?? "")
        ) {
          // calculate position breadcrumb bar must scroll to to center the current item
          const itemLeft = item.offsetLeft;
          const itemWidth = item.offsetWidth;
          const containerWidth = breadcrumbContainer.offsetWidth;

          const scrollLeftPosition =
            itemLeft - containerWidth / 2 + itemWidth / 2;

          // Smoothly scroll to the calculated position
          breadcrumbContainer.scrollTo({
            left: scrollLeftPosition,
            behavior: "smooth",
          });
        }
      });
    }
  }, [currentDimension]);

  function handleClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | KeyboardEvent,
    linkTo: string
  ) {
    event.preventDefault();
    if (scorecardData) {
      // save validationList in Frontend
      saveValidationList(
        validationList,
        currentDimension,
        setValidationList,
        scorecardData
      );
      // save data of current dimension in DB
      if (currentDimension === "start") {
        addCustomerMasterData(
          scorecardData.master_data,
          isAdmin ? customer_id ?? "" : customerId ?? ""
        )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            setShowAlert(true);
            console.log(error);
          });
      } else if (currentDimension === "general") {
        addCustomerGeneral(
          scorecardData.general,
          isAdmin ? customer_id ?? "" : customerId ?? ""
        )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            setShowAlert(true);
            console.log(error);
          });
      } else if (currentDimension === "questionnaire") {
        addCustomerQuestionnaire(
          scorecardData.questionnaire,
          isAdmin ? customer_id ?? "" : customerId ?? ""
        )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            setShowAlert(true);
            console.log(error);
          });
      } else if (currentDimension === "submit") {
        console.log("save nothing");
      } else {
        const dimension: Dimension = scorecardData.dimensions.filter((dim) => {
          return dim.dimension_title === DimensionsMap.get(currentDimension);
        })[0];
        addCustomerDimensions(
          [dimension],
          isAdmin ? customer_id ?? "" : customerId ?? ""
        )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            setShowAlert(true);
            console.log(error);
          });
      }
    }
    if (customerId && !isAdmin)
      updateCustomerData(customerId, {
        scorecard_finished: submitted,
        first_time: false,
      });
    if (isAdmin) {
      if (customer_id) {
        updateCustomerData(customer_id, {
          scorecard_finished: submitted,
          first_time: false,
        });
      }
      if (setCurrentDimension) setCurrentDimension(linkTo);
    } else {
      navigate(`/${linkTo}`);
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (isAdmin) {
      //logic should not be executed inside the cutomer data section
      const excludedElement = document.getElementById("excluded-section");
      if (excludedElement && excludedElement.contains(event.target as Node)) {
        return;
      }

      if (event.key === "Tab" && scorecardData) {
        if (setCurrentDimension)
          setCurrentDimension((prev) => {
            let nextDimension = prev;
            if (isStepCompleted(prev, scorecardData)) {
              event.preventDefault();
              nextDimension = getPreviousAndFollowingKey(
                DimensionsMap,
                prev
              ).followingKey;
              if (prev === "success") {
                return "success";
              } else {
                handleClick(event, nextDimension);
                return nextDimension;
              }
            }
            return nextDimension;
          });
      }
    } else if (!isAdmin) {
      if (event.key === "Tab" && scorecardData) {
        const nextDimension = getPreviousAndFollowingKey(
          DimensionsMap,
          currentDimension
        ).followingKey;
        if (isStepCompleted(currentDimension, scorecardData)) {
          if (!(currentDimension === "submit")) {
            handleClick(event, nextDimension);
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDimension, scorecardData]);

  const breadcrumb = (dimension: string, name: string, isCurrent: boolean) => {
    return (
      <BreadcrumbItem
        key={dimension}
        isCurrentItem={isCurrent}
        onClick={(event: any) => {
          if (!isCurrent) {
            handleClick(event, dimension);
          }
        }}
        tabIndex={-1}
      >
        {name}
        {dimension !== "submit" &&
        dimension !== "questionnaire" &&
        scorecardData ? (
          firstTime ? (
            containsString(validationList, dimension) ? (
              isStepCompleted(dimension, scorecardData) ? (
                <CompletedIcon sx={{ ml: 0.5 }} fontSize="small" />
              ) : (
                <MissingIcon sx={{ ml: 0.5 }} fontSize="small" />
              )
            ) : isStepCompleted(dimension, scorecardData) ? (
              <CompletedIcon sx={{ ml: 0.5 }} fontSize="small" />
            ) : null
          ) : isStepCompleted(dimension, scorecardData) ? (
            <CompletedIcon sx={{ ml: 0.5 }} fontSize="small" />
          ) : (
            <MissingIcon sx={{ ml: 0.5 }} fontSize="small" />
          )
        ) : null}
      </BreadcrumbItem>
    );
  };

  const breadcrumbs: JSX.Element[] = [];

  DimensionsMap.forEach((value, key) => {
    if (isAdmin && key !== "submit") {
      breadcrumbs.push(breadcrumb(key, value, key === currentDimension));
    } else if (!isAdmin) {
      breadcrumbs.push(breadcrumb(key, value, key === currentDimension));
    }
  });

  return (
    <>
      <NavBox ref={breadcrumbRef} isAdmin={isAdmin}>
        <BreadcrumbNav maxItems={9} separator={<NextIcon />}>
          {breadcrumbs}
        </BreadcrumbNav>

        {scrollable ? (
          <KeyboardDoubleArrowLeftIcon
            style={{
              position: "absolute",
              left: 0,
              backgroundColor: "white",
              height: "52px",
              color: `${Colors.Grey}`,
              fontSize: "16px",
            }}
          />
        ) : null}
        {scrollable ? (
          <KeyboardDoubleArrowRightIcon
            style={{
              position: "absolute",
              right: 0,
              backgroundColor: "white",
              height: "52px",
              color: `${Colors.Grey}`,
              fontSize: "16px",
            }}
          />
        ) : null}
      </NavBox>
    </>
  );
};

export default BreadcrumbBar;
