// map object advantage to normal object: key order is kept & efficient storage
export const DimensionsMap = new Map([
  ["general", "Allgemein"],
  ["strategy", "Strategie"],
  ["organization", "Organisation"],
  ["culture", "Kultur"],
  ["ressources", "Ressourcen"],
  ["processlandscape", "Prozess Landschaft"],
  ["success", "Erfolge"],
  ["questionnaire", "Fragebogen"],
  ["submit", "Abschicken"],
]);

export function getKeyByIndex(map: Map<string, string>, index: number) {
  return Array.from(map.keys())[index];
}

export function getKeysBefore(map: Map<string, string>, searchKey: string) {
  const keysArray = Array.from(map.entries(), ([key, value]) => key);
  const keyIndex = keysArray.indexOf(searchKey);
  // Get all elements before searchKey
  return keysArray.slice(0, keyIndex);
}

export function getPreviousAndFollowingKey(
  map: Map<string, string>,
  searchKey: string
) {
  const keysArray = Array.from(map.entries(), ([key, value]) => key);
  const index = keysArray.indexOf(searchKey);
  const keys = { previousKey: "", followingKey: "" };
  if (index !== -1) {
    if (index > 0) keys.previousKey = keysArray[index - 1];
    if (index < keysArray.length - 1) keys.followingKey = keysArray[index + 1];
  } else {
    console.error(`${keysArray} not found in the array.`);
  }
  return keys;
}
