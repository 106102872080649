export const relevanceSelection = [
  "überhaupt nicht relevant",
  "nur gering relevant",
  "durchschnittlich relevant",
  "sehr relevant",
  "im höchsten Maße relevant",
];

// auspraegung
export const developmentSelection = [
  "überhaupt nicht erfüllt",
  "nur gering erfüllt",
  "durchschnittlich erfüllt",
  "sehr erfüllt",
  "im höchsten Maße erfüllt",
];

export const targetedDigitizationDegreeSelection = [
  "überhaupt keinen",
  "einen geringen",
  "einen mittleren",
  "einen hohen",
  "einen sehr hohen",
];

export const currentDigitizationDegreeSelection = [
  "keine digitale Validierung",
  "geringfügige digitale Validierung",
  "mittlere digitale Validierung",
  "umfangreiche digitale Validierung",
  "voll ausgeprägte digitale Validierung",
];

export const GeneralAccordionDescription = (dimension: string) =>
  `Allgemeine Bewertung der Dimension ${dimension} als Bestandteil eines ganzheitlichen Ansatzes zur digitalen Validierung in Unternehmen.`;

export const RelevanceDevelopmentQuestionDimension = (dimension: string) =>
  `Welche Relevanz hat die Dimension ${dimension} für Ihr Unternehmen? Wie ist allgemein die Ausprägung dieser Dimension in Ihrem Unternehmen zu beurteilen?`;

export const RelevanceDevelopmentQuestionSuccessfactor = `Welche Relevanz hat dieser Erfolgsfaktor für Ihr Unternehmen? Wie ist allgemein die Ausprägung dieses Erfolgfaktors in Ihrem Unternehmen zu beurteilen?`;
