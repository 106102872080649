import { Alert } from "@mui/material";
import { FC, useState } from "react";
import AlertSnackBar from "../../components/Alert/CustomAlert";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import CustomButton from "../../components/CustomButton/CustomButton";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import CircularLoading from "../../components/Loading/Loading";
import { DimensionsMap } from "../../constants/DimensionsMap";
import { isStepCompleted } from "../../helpers/checkCompletionHelpers";
import { useDirectToStartpage } from "../../hooks/useDirectToStartpage";
import { useGetUser } from "../../hooks/useFetchUserData";
import {
  getScorecardData,
  saveScorecard,
  updateCustomerData,
} from "../../services/serviceCalls";

interface SubmitPageProps {
  currentDimension: string;
  isAdmin?: boolean;
}

const SubmitPage: FC<SubmitPageProps> = ({ currentDimension, isAdmin }) => {
  const {
    customerId,
    validationList,
    scorecardData,
    setShowAlert,
    submitted,
    firstTime,
  } = useAppContext();

  useDirectToStartpage(scorecardData, isAdmin);

  const [isFinished, setIsFinished] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const { fetchUser } = useGetUser(customerId);

  const handleClick = () => {
    if (customerId) {
      getScorecardData(customerId)
        .then((res) => {
          const backendScorecardData = res.data;
          let scorecardCompleted = true;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [key, value] of DimensionsMap) {
            if (backendScorecardData) {
              if (!isStepCompleted(key, backendScorecardData))
                scorecardCompleted = false;
            }
          }
          if (validationList.length > 0 && scorecardCompleted) {
            backendScorecardData && customerId
              ? saveScorecard(backendScorecardData, customerId)
                  .then((res) => {
                    //save that user submitted the scorecard
                    setIsFinished(true);
                    setShowFeedback(true);
                    return updateCustomerData(customerId, {
                      scorecard_finished: true,
                      first_time: firstTime,
                    });
                  })
                  .then((res) => {
                    // disable fields without reload
                    fetchUser();
                  })
                  .catch((err) => {
                    setShowAlert(true);
                  })
              : setShowAlert(true);
          } else {
            setIsFinished(false);
            setShowFeedback(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    //check if Tab was pressed and interprete it as a click on the button

    if (event.key === "Tab") {
      event.preventDefault();
      handleClick();
    }
  };

  return scorecardData === undefined ? (
    <CircularLoading center />
  ) : (
    <div className="App-container">
      <Header currentDimension={currentDimension} />
      <main>
        <CustomButton
          onClick={handleClick}
          disabled={submitted}
          onKeyDown={handleKeyDown}
          buttonText={submitted ? "Abgeschickt!" : "Abschicken"}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "3em",
            marginBottom: "3em",
          }} // center button
        />
        {showFeedback ? (
          <Alert
            severity={isFinished ? "success" : "error"}
            variant="filled"
            style={{
              width: "300px",
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            {isFinished
              ? "Daten wurden erfolgreich abgeschickt!"
              : "Fehlende Werte! Bitte füllen Sie alle Pflichtfelder aus."}
          </Alert>
        ) : null}
        <AlertSnackBar
          text="Die Daten konnten nicht gespeichert werden! Bitte versuchen Sie es
        nochmal."
          type="error"
        />
      </main>
      <Footer
        currentDimension={currentDimension}
        saveData={() => console.log("save nothing")}
      />
    </div>
  );
};

export default SubmitPage;
