import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { FC, useEffect, useMemo, useState } from "react";
import * as Colors from "../../globalStyles/Colors";
import { getComparator, Order } from "../../helpers/comparatorHelpers";
import { downloadEmail } from "../../helpers/linkEmailHelpers";
import { Roles } from "../../types/roleTypes";
import { TableData } from "../../types/TableDataTypes";
import { useAdminContext } from "../AdminProvider/AdminProvider";
import { useAppContext } from "../AppProvider/AppProvider";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolBar";

interface EnhancedTableProps {
  data: TableData[];
}

const EnhancedTable: FC<EnhancedTableProps> = ({ data }) => {
  const { tableData } = useAdminContext();
  const { submitted } = useAppContext();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof TableData>("customer_name");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<TableData[]>([]);

  // state for link copy tooltip text
  const [email, setEmail] = useState(false);

  const { hasRole } = useKeycloak();

  useEffect(() => {
    setRows(data);
  }, [data, tableData]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (tableData) {
      setSearchValue(event.target.value);
      setRows(
        tableData.filter((row) =>
          row.customer_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
      );
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleEmailClick = (
    customerId: string,
    customerName: string,
    password: string
  ) => {
    downloadEmail(customerId, customerName, password);
    setEmail(true);
    setTimeout(() => setEmail(false), 2000);
  };

  const handleRowClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleScorecardClick = (id: string) => {
    const link = `/scorecard/${id}`;
    window.open(link, "_blank");
  };

  const handleResultsClick = (id: string) => {
    const link = `/results/${id}`;
    window.open(link, "_blank");
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      rows
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const IconForStatus = (scorecard_finished: boolean) => {
    if (scorecard_finished) {
      return <CheckCircleIcon color="primary" style={{ padding: "8px 0" }} />;
    } else {
      return (
        <CheckCircleOutlineIcon
          color="secondary"
          style={{ padding: "8px 0" }}
        />
      );
    }
  };

  return (
    <Box sx={{ width: "100%", pt: 5 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          selected={selected}
          setSelected={setSelected}
          searchValue={searchValue}
          handleSearch={handleSearch}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      hasRole([Roles.DELETE])
                        ? handleRowClick(event, row.id)
                        : null
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {hasRole([Roles.DELETE]) ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    ) : null}
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding={hasRole([Roles.DELETE]) ? "none" : "normal"}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {row.customer_name}
                        <Tooltip title={"Scorecard bearbeiten"} placement="top">
                          <IconButton
                            onClick={(event: React.MouseEvent<unknown>) => {
                              // prevent row to be selected from icon click
                              event.stopPropagation();
                              handleScorecardClick(row.id);
                            }}
                          >
                            <EditNoteIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      {new Date(
                        row.time_period_start * 1000
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      {new Date(
                        row.time_period_end * 1000
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">{row.contact_person}</TableCell>
                    <TableCell align="right">{row.author}</TableCell>
                    <TableCell align="right">
                      {IconForStatus(row.scorecard_finished)}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={
                          email ? "Heruntergeladen!" : "Email herunterladen"
                        }
                        placement="top"
                      >
                        <IconButton
                          onClick={(event: React.MouseEvent<unknown>) => {
                            // prevent row to be selected from icon click
                            event.stopPropagation();
                            handleEmailClick(
                              row.id,
                              row.customer_name,
                              row.password
                            );
                          }}
                        >
                          {row.password !== "" ? (
                            <MailLockOutlinedIcon />
                          ) : (
                            <MailOutlineIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={
                          row.scorecard_finished ? "" : "Noch nicht verfügbar"
                        }
                        placement="top"
                      >
                        <IconButton
                          onClick={
                            row.scorecard_finished
                              ? (event: React.MouseEvent<unknown>) => {
                                  // prevent row to be selected from icon click
                                  event.stopPropagation();
                                  if (row.scorecard_finished)
                                    handleResultsClick(row.id);
                                }
                              : (event: React.MouseEvent<unknown>) => {
                                  event.stopPropagation();
                                }
                          }
                        >
                          {row.scorecard_finished ? (
                            <ScatterPlotIcon sx={{ color: Colors.MintGreen }} />
                          ) : (
                            <ScatterPlotIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default EnhancedTable;
