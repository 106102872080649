import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, TextField, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DimensionsMap } from "../../constants/DimensionsMap";
import {
  developmentSelection,
  RelevanceDevelopmentQuestionSuccessfactor,
  relevanceSelection,
} from "../../constants/SelectionValues";

import {
  QuestionWrapper,
  StyledFormLabel,
} from "../../globalStyles/QuestionWrapper.style";
import { isSuccessfactorCompleted } from "../../helpers/checkCompletionHelpers";
import { containsString } from "../../helpers/helpers";
import { addCustomerDimensions } from "../../services/serviceCalls";
import {
  Dimension,
  ResultsEvaluationDimension,
  Successfactor,
} from "../../types/ScorecardTypes";
import { UpdateType } from "../../types/UpdateTypes";
import { useAppContext } from "../AppProvider/AppProvider";
import ShortSelect from "../CustomSelect/ShortSelect";
import MultiLineTextFieldQuestion from "../TextFieldQuestion/MultiLineTextFieldQuestion";
import {
  CompletedIcon,
  Description,
  DescriptionTitle,
  InlineSelectWrapper,
  MissingIcon,
  StyledAccordion,
  StyledAccordionSummary,
  TextIconWrapper,
} from "./CustomAccordion.style";

interface SuccessfactorAccordionProps {
  dimension: string;
  currentSuccessfactor: Successfactor;
  expand: boolean;
  handleAccordionChange: (accordionTitle: string) => void;
  title: string;
  disabled?: boolean;
  setReloadDimension?: React.Dispatch<React.SetStateAction<boolean>>;
  closedAccordion: string;
  isAdmin?: boolean;
}

const SuccessfactorAccordion: FC<SuccessfactorAccordionProps> = ({
  dimension,
  currentSuccessfactor,
  expand,
  handleAccordionChange,
  title,
  disabled,
  setReloadDimension,
  closedAccordion,
  isAdmin,
}) => {
  const {
    customerId,
    scorecardData,
    setScorecardData,
    validationList,
    setShowAlert,
    firstTime,
  } = useAppContext();

  // check flag for validating the fields when acoordion is being closed
  const [checkForCompletion, setCheckForCompletion] = useState<boolean>(false);

  // use a Ref for initial focus on the correct Accordion
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  // check for validating fields when coming back to this dimension page
  const isValidated = containsString(validationList, dimension);

  const dimensionsEntry = scorecardData ? scorecardData.dimensions : [];

  const currentDimension: Dimension = dimensionsEntry.filter((dim) => {
    return dim.dimension_title === DimensionsMap.get(dimension);
  })[0];

  const { customer_id } = useParams();

  useEffect(() => {
    if (expand && textFieldRef.current) {
      textFieldRef.current.focus({ preventScroll: true });
    }
  }, [expand]);

  const updateDimensionEvaluation = (
    updatedEntry: ResultsEvaluationDimension
  ) => {
    const newDimensions: Dimension[] = dimensionsEntry.map((dim) =>
      dim.dimension_title === DimensionsMap.get(dimension)
        ? ({
            ...dim,
            successfactors: dim.successfactors.map((sf) =>
              sf.title === currentSuccessfactor.title
                ? { ...sf, results: updatedEntry }
                : sf
            ),
          } as Dimension)
        : dim
    );

    if (scorecardData) {
      setScorecardData({
        ...scorecardData,
        dimensions: newDimensions,
      });
    }
  };

  const updateArgumentation = (value: string) => {
    const successfactorResults = currentSuccessfactor.results;

    const updatedEntry: ResultsEvaluationDimension = {
      ...successfactorResults,
      argumentation: {
        value: value,
        required: currentSuccessfactor.results.argumentation.required,
      },
    };

    updateDimensionEvaluation(updatedEntry);
  };

  const updateRelevanceDevelopment = (value: number, type: UpdateType) => {
    const successfactorResults = currentSuccessfactor.results;

    const updatedEntry: ResultsEvaluationDimension = {
      argumentation: successfactorResults.argumentation,
      relevance:
        type === "relevance"
          ? {
              value: value,
              required: currentSuccessfactor.results.relevance.required,
            }
          : {
              value: currentSuccessfactor.results.relevance.value,
              required: currentSuccessfactor.results.relevance.required,
            },
      development:
        type === "development"
          ? {
              value: value,
              required: currentSuccessfactor.results.development.required,
            }
          : {
              value: currentSuccessfactor.results.development.value,
              required: currentSuccessfactor.results.development.required,
            },
    };

    updateDimensionEvaluation(updatedEntry);
  };

  const handleChange = () => {
    addCustomerDimensions(
      [currentDimension],
      isAdmin ? customer_id ?? "" : customerId ?? ""
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setShowAlert(true);
        console.log(error);
      });
    handleAccordionChange(title);
  };

  return (
    <StyledAccordion expanded={expand} onChange={() => handleChange()}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ display: "block" }}>
          <TextIconWrapper>
            Erfolgsfaktor
            {!firstTime ||
            isValidated ||
            checkForCompletion ||
            title === closedAccordion
              ? (() => {
                  if (!checkForCompletion) setCheckForCompletion(true);
                  return isSuccessfactorCompleted(currentSuccessfactor) ? (
                    <CompletedIcon sx={{ ml: 0.5 }} fontSize="small" />
                  ) : (
                    <MissingIcon sx={{ ml: 0.5 }} fontSize="small" />
                  );
                })()
              : ""}
          </TextIconWrapper>
          {/* This textfiled is only for focus */}
          <TextField
            style={{ position: "absolute", opacity: 0, height: 0, width: 0 }}
            inputRef={textFieldRef}
          />
          <Typography variant="h6">{currentSuccessfactor.title}</Typography>
        </div>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Description>
          <DescriptionTitle>Erläuterung</DescriptionTitle>
          <Typography>{currentSuccessfactor.explanation}</Typography>
          <DescriptionTitle topSpace>
            Mögliche Messgrößen bzw. Nachweise als Bewertungsgrundlage:
          </DescriptionTitle>

          <ul
            style={{
              marginLeft: "-20px",
              marginBottom: "-0px",
              marginTop: "1px",
            }}
          >
            {currentSuccessfactor.measurements.map((value) => (
              <li key={value}>
                <Typography>{value}</Typography>
              </li>
            ))}
          </ul>
        </Description>
        <QuestionWrapper>
          <StyledFormLabel
            isError={
              ((currentSuccessfactor.results.relevance.value === 0 &&
                currentSuccessfactor.results.relevance.required === true) ||
                (currentSuccessfactor.results.development.value === 0 &&
                  currentSuccessfactor.results.development.required ===
                    true)) &&
              (checkForCompletion || isValidated)
            }
            required={
              currentSuccessfactor.results.relevance.required === true ||
              currentSuccessfactor.results.development.required === true
            }
          >
            {RelevanceDevelopmentQuestionSuccessfactor}
          </StyledFormLabel>
          <InlineSelectWrapper>
            <ShortSelect
              selectItems={relevanceSelection}
              type="relevance"
              label="Relevanz"
              value={currentSuccessfactor.results.relevance.value}
              updateData={updateRelevanceDevelopment}
              isValidated={checkForCompletion || isValidated}
              disabled={disabled}
              required={currentSuccessfactor.results.relevance.required}
            />
            <ShortSelect
              selectItems={developmentSelection}
              type="development"
              label="Ausprägung"
              value={currentSuccessfactor.results.development.value}
              updateData={updateRelevanceDevelopment}
              floatRight
              isValidated={checkForCompletion || isValidated}
              disabled={disabled}
              required={currentSuccessfactor.results.development.required}
            />
          </InlineSelectWrapper>
        </QuestionWrapper>
        <MultiLineTextFieldQuestion
          question="Begründung"
          placeholder="Begründung"
          value={currentSuccessfactor.results.argumentation.value}
          updateData={updateArgumentation}
          isValidated={checkForCompletion || isValidated}
          disabled={disabled}
          setReloadDimension={setReloadDimension}
          currentSuccessfactor={currentSuccessfactor}
          setCheckForCompletion={setCheckForCompletion}
          currentDimension={currentDimension}
          isAdmin={isAdmin}
          required={currentSuccessfactor.results.argumentation.required}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default SuccessfactorAccordion;
