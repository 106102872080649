import { DimensionsMap } from "../constants/DimensionsMap";
import {
  Dimension,
  General,
  GeneralEvaluationDimension,
  MasterData,
  ScorecardData,
  Successfactor,
} from "../types/ScorecardTypes";

// Check for fields in General Page
export const isGeneralPageCompleted = (generalEntry: General) => {
  const selfUnderstandingCheck =
    generalEntry.self_understanding_digital_validation.every(
      (item) => item.answer !== "" || item.required === false
    );

  const generalEval = generalEntry.general_evaluation_digital_validation;

  const generalEvalCheck =
    (generalEval.relevance_development.relevance !== 0 ||
      !generalEval.relevance_development.required) &&
    (generalEval.relevance_development.development ||
      !generalEval.relevance_development.required) !== 0 &&
    (generalEval.targeted_digitization_degree.value !== 0 ||
      !generalEval.targeted_digitization_degree.required) &&
    (generalEval.current_digitization_degree.value !== 0 ||
      !generalEval.current_digitization_degree.required) &&
    (generalEval.well_developed_areas.answer !== "" ||
      !generalEval.well_developed_areas.required) &&
    (generalEval.optimization_potential.answer !== "" ||
      !generalEval.optimization_potential.required);

  return generalEvalCheck && selfUnderstandingCheck;
};

// check for fields in Dimension Page
export const isGeneralEvaluationCompleted = (
  general_evaluation: GeneralEvaluationDimension
) => {
  return (
    (general_evaluation.self_understanding.value !== "" ||
      !general_evaluation.self_understanding.required) &&
    (general_evaluation.relevance.value !== 0 ||
      !general_evaluation.relevance.required) &&
    (general_evaluation.development.value !== 0 ||
      !general_evaluation.development.required) &&
    (general_evaluation.argumentation.value !== "" ||
      !general_evaluation.argumentation.required)
  );
};

export const isAllSuccessfactorsCompleted = (dimension: Dimension) => {
  return dimension.successfactors.every((factor) =>
    isSuccessfactorCompleted(factor)
  );
};

export const isSuccessfactorCompleted = (successfactor: Successfactor) => {
  return (
    (successfactor.results.argumentation.value !== "" ||
      !successfactor.results.argumentation.required) &&
    (successfactor.results.relevance.value !== 0 ||
      !successfactor.results.relevance.required) &&
    (successfactor.results.development.value !== 0 ||
      !successfactor.results.development.required)
  );
};

// check for fields in Start Page
export const isStartPageCompleted = (customerEntry: MasterData) => {
  return (
    customerEntry.customer_name !== "" &&
    customerEntry.time_period_start !== 0 &&
    customerEntry.time_period_end !== 0 &&
    customerEntry.contact_person !== "" &&
    customerEntry.comments !== ""
  );
};

export const isStepCompleted = (
  currentDimension: string,
  scorecardData: ScorecardData
): boolean => {
  if (currentDimension === "general") {
    return isGeneralPageCompleted(scorecardData.general);
  } else if (currentDimension === "submit") {
    return true;
  } else if (currentDimension === "questionnaire") {
    return true;
  } else {
    const dimension = scorecardData.dimensions.filter((dim) => {
      return dim.dimension_title === DimensionsMap.get(currentDimension);
    })[0];
    return (
      isGeneralEvaluationCompleted(dimension.general_evaluation) &&
      isAllSuccessfactorsCompleted(dimension)
    );
  }
};
