import { Box } from "@mui/material";
import { FC } from "react";
import { CustomLoading, LoadingBox } from "./Loading.style";

interface CircularLoadingProps {
  center?: boolean;
}

const CircularLoading: FC<
  CircularLoadingProps & React.ComponentProps<typeof Box>
> = ({ center, ...rest }) => {
  return (
    <LoadingBox center={center} {...rest}>
      <CustomLoading />
    </LoadingBox>
  );
};

export default CircularLoading;
