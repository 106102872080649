import {
  Alert,
  Box,
  Collapse,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { isScorecardData } from "../../helpers/scorecardInterfaceChecker";
import { getTemplate, storeTemplate } from "../../services/serviceCalls";
import { ScorecardData } from "../../types/ScorecardTypes";
import CustomButton from "../CustomButton/CustomButton";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";
import CircularLoading from "../Loading/Loading";
import { ModalContent, StyledBox } from "./CustomModal.style";

interface ExterneScorecardModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const TemplateModal: FC<ExterneScorecardModalProps> = ({ open, setOpen }) => {
  const { getToken } = useKeycloak();

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:600px) and (max-width:960px)"
  );

  // Determine the number of rows based on screen size
  const rows = isSmallScreen ? 10 : isMediumScreen ? 15 : 20;

  // has those three types as when template retrieved from db it can either be ScorecardData or boolean but once changed in the textfield it is a string
  const [jsonTemplate, setJsonTemplate] = useState<
    ScorecardData | boolean | string
  >(false);

  useEffect(() => {
    const token = getToken();
    if (token) {
      getTemplate(token)
        .then((res) => {
          setJsonTemplate(res.data);
        })
        .catch((err) => console.log(err));
    }
    setShowAlert(false);
    setIsSuccess(false);
    // eslint-disable-next-line
  }, [open]);

  const handleClose = (event: object, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSuccess(false);
    setJsonTemplate(event.target.value);
  };

  const handleOnSave = () => {
    setShowAlert(false);
    const token = getToken();
    // jsonTemplate once edited will be string
    if (typeof jsonTemplate === "string") {
      try {
        const parsedJSON = JSON.parse(jsonTemplate);
        console.log(isScorecardData(parsedJSON));
        if (token && isScorecardData(parsedJSON)) {
          setShowLoading(true);
          storeTemplate(token, jsonTemplate)
            .then((res) => {
              setShowLoading(false);
              setIsSuccess(true);
            })
            .catch((err) => {
              console.log(err);
              setShowLoading(false);
              setShowAlert(true);
            });
        } else {
          setShowAlert(true);
        }
      } catch (e) {
        setShowAlert(true);
        setShowLoading(false);
        console.error(e);
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox>
        <ModalContent>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography
                id="extern_scorecard_modal_title"
                variant="h6"
                component="h2"
              >
                Scorecard Template bearbeiten
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Typography id="extern_scorecard_modal_description" sx={{ mt: 2 }}>
            Bearbeite das JSON, um das Template anzupassen.
          </Typography>
          <TextField
            id="template-json"
            label="Template"
            variant="outlined"
            fullWidth
            rows={rows}
            multiline
            defaultValue={JSON.stringify(jsonTemplate, null, 2)}
            onChange={handleChange}
          />
          <CustomButton
            buttonText="Speichern"
            style={{ margin: "10px" }}
            onClick={handleOnSave}
            disabled={typeof jsonTemplate !== "string"}
          />

          {showLoading ? <CircularLoading /> : null}
          {isSuccess ? (
            <CheckCircleIcon
              color="primary"
              style={{ display: "flex", margin: "0 auto" }}
            />
          ) : null}

          <Collapse in={showAlert}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ m: 2 }}
            >
              Beim Speichern ist etwas schief gelaufen. Bitte überprüfen Sie, ob
              Sie die JSON-Struktur korrekt beibehalten haben.
            </Alert>
          </Collapse>
        </ModalContent>
      </StyledBox>
    </Modal>
  );
};

export default TemplateModal;
