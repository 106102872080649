import { FC, useState } from "react";
import {
  QuestionWrapper,
  StyledFormLabel,
} from "../../globalStyles/QuestionWrapper.style";
import { StyledTextField } from "./TextFieldQuestion.style";

interface SingleTextFieldQuestionProps {
  question: string;
  placeholder: string;
  value: string;
  updateData: (input: string, question?: string) => void;
  isValidated?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const SingleTextFieldQuestion: FC<SingleTextFieldQuestionProps> = ({
  question,
  placeholder,
  value,
  updateData,
  isValidated,
  disabled,
  required,
}) => {
  const [input, setInput] = useState(value);

  const handleInputChange = (event: any) => {
    setInput(event.target.value);
    updateData(event.target.value, question);
  };

  const isError = input === "" && isValidated && required === true;

  return (
    <QuestionWrapper>
      <StyledFormLabel isError={isError}>{question}</StyledFormLabel>
      <StyledTextField
        isError={isError}
        error={isError}
        placeholder={placeholder}
        value={input}
        onChange={handleInputChange}
        disabled={disabled}
        variant="outlined"
      />
    </QuestionWrapper>
  );
};

export default SingleTextFieldQuestion;
