import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../components/AppProvider/AppProvider";
import CustomToolbar from "../../components/CustomToolbar/CustomToolbar";
import IconWithText from "../../components/Header/IconWithText";
import CircularLoading from "../../components/Loading/Loading";
import ScatterPlot from "../../components/ScatterPlot/ScatterPlot";
import { useFetchScorecardData } from "../../hooks/useFetchUserData";
import {
  getCustomerData,
  getPlotData,
  save_svg_pdf,
} from "../../services/serviceCalls";
import { PlotData, SVGItem } from "../../types/SVGDataTypes";
import InfoPage from "../StatePages/InfoPage";
import {
  ResultsSection,
  ResultsWrapper,
  ScatterSection,
  ScatterSectionDimension,
} from "./ResultsPage.style";

interface DimensionPlotItem {
  name: string;
  mean_relevance: number;
  mean_development: number;
  attributes?: Record<any, any>;
}

const ResultsPage = () => {
  const { customer_id } = useParams();
  const [plotData, setPlotData] = useState<PlotData>();
  const [plotDimension, setPlotDimension] = useState("");
  const [dimensionPlotData, setDimensionPlotData] = useState<
    DimensionPlotItem[]
  >([{ name: "", mean_development: 0, mean_relevance: 0 }]);
  const [scorecardFinished, setScorecardFinished] = useState<
    boolean | undefined
  >();

  const [svgStrings, setSVGStrings] = useState<SVGItem[]>([]);

  const { scorecardData } = useAppContext();
  const { fetchScorecardData } = useFetchScorecardData(customer_id);

  const navigate = useNavigate();

  useEffect(() => {
    fetchScorecardData();
    if (customer_id) {
      getCustomerData(customer_id)
        .then((res) => {
          setScorecardFinished(res.data.scorecard_finished);
          getPlotData(customer_id).then((res) => {
            setPlotData(res.data);
          });
        })
        .catch((err) => {
          console.log(err);
          setScorecardFinished(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (plotData) {
      extractDimensionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotData]);

  useEffect(() => {
    if (svgStrings.length > 6 && scorecardData) {
      save_svg_pdf(svgStrings, scorecardData.master_data.customer_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgStrings]);

  const extractDimensionData = () => {
    // Für jede Dimension werden Daten extrahiert und an ScatterPlot übergeben
    // UseEffect in ScatterPlot erstellt automatische eine Grafik wenn sich der Datensatz ändert
    // setTimeout, da ansonsten das redndering der ScatterPlot Komponente nicht abgeschlossen ist
    const dimensions = [
      "Strategie",
      "Organisation",
      "Kultur",
      "Ressourcen",
      "Prozess Landschaft",
      "Erfolge",
    ];

    for (let i = 0; i < dimensions.length; i++) {
      const dimension = dimensions[i % dimensions.length];
      setTimeout(() => {
        if (plotData) {
          const transformedplotData = Object.keys(plotData).map(
            (key: string) => plotData[key as keyof PlotData]
          );
          const dimensionData = transformedplotData.find(
            (dim) => dim.name === dimension
          );
          let dimensionPlotData;
          if (
            dimensionData &&
            dimensionData["development"] &&
            dimensionData["relevance"] &&
            dimensionData["attributes"]
          ) {
            const developmentArray = dimensionData["development"];
            const attributesArray = dimensionData["attributes"];
            dimensionPlotData = dimensionData["relevance"].map(
              (value, index) => {
                return {
                  name: attributesArray[index].title,
                  mean_relevance: value,
                  mean_development: developmentArray[index],
                  attributes: dimensionData["attributes"],
                };
              }
            );
            setDimensionPlotData(dimensionPlotData);
            setPlotDimension(dimension);
          }
        }
      }, 100 * i);
    }
  };

  const handleDataPointClick = (name: string) => {
    // Window soll nur beim Klicken auf einen Dimensionsdatenpunkt geöffnet werden
    if (typeof name === "string") {
      setPlotDimension(name);
      navigate(`/results/${customer_id}/${name}`);
    }
  };

  return (
    <ResultsWrapper>
      <header className="Admin-container">
        <CustomToolbar />
      </header>

      {scorecardFinished === undefined ? (
        <CircularLoading />
      ) : scorecardFinished ? (
        <>
          <ResultsSection>
            <IconWithText text="Scorecard Auswertung" />
            <Container>
              {plotData === undefined ? (
                <CircularLoading />
              ) : (
                <ScatterSection>
                  <ScatterPlot
                    plotData={Object.keys(plotData).map(
                      (key: string) => plotData[key as keyof PlotData]
                    )}
                    handleDataPointClick={handleDataPointClick}
                    plotType="Scorecard"
                    setSVGStrings={setSVGStrings}
                  />
                </ScatterSection>
              )}
            </Container>
          </ResultsSection>
          {plotData === undefined || dimensionPlotData === undefined ? (
            ""
          ) : (
            <ScatterSectionDimension>
              <ScatterPlot
                plotData={dimensionPlotData}
                plotType={plotDimension}
                setSVGStrings={setSVGStrings}
              />
            </ScatterSectionDimension>
          )}
        </>
      ) : (
        <InfoPage text="Auswertung für diese Scorecard exisitert noch nicht." />
      )}
    </ResultsWrapper>
  );
};

export default ResultsPage;
