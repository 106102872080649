import {
  Body,
  Button,
  Container,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Preview,
  Section,
  Text,
} from "@react-email/components";
import * as Colors from "../../globalStyles/Colors";

interface ScoreCardEmailProps {
  link: string;
  password?: string;
}

export const ScoreCardEmail = ({ link, password }: ScoreCardEmailProps) => (
  <Html>
    <Head />
    <Preview>Ihr Link zur Scorecard</Preview>
    <Body style={main}>
      <Container style={container}>
        <Img
          src={
            "https://arbeit-muss-schmecken.de/wp-content/uploads/2022/03/Group-Round.png"
          }
          width="50"
          height="50"
          alt="dhc_logo"
        />
        <Heading style={heading}>Ihr Link zur Scorecard</Heading>
        <Section style={paragraphContent}>
          <Text style={paragraph}>Sehr geehrte Damen und Herren,</Text>
          <Text style={paragraph}>
            um unsere Beratung optimal auf Ihre Bedürfnisse abzustimmen, ist es
            wichtig, dass wir den aktuellen Stand Ihrer Validierung kennen.
          </Text>
        </Section>

        <Section style={paragraphContent}>
          <Text style={paragraph}>
            Bitte füllen Sie die Scorecard sorgfältig aus. Sie finden diese
            unter dem untenstehenden Link. Mit diesen Informationen können wir
            unsere Beratung gezielt auf den Validierungsstand Ihres Unternehmens
            anpassen.
          </Text>
          <Section style={buttonContainer}>
            <Button style={button} href={link}>
              Link zur Scorecard
            </Button>
          </Section>
        </Section>
        {password ? (
          <Section style={paragraphContent}>
            <Text style={paragraph}>
              Um den Link zu öffnen, benötigen Sie das folgende Passwort:{" "}
              {password}
            </Text>
          </Section>
        ) : null}

        <Section style={paragraphContent}>
          <Text style={paragraph}>
            Mit freundlichen Grüßen
            <br />
            Ihr DHC Team
          </Text>
        </Section>

        <Section style={{ ...paragraphContent, paddingBottom: 30 }}>
          <Hr style={hr} />
          <Text
            style={{
              ...paragraph,
              fontSize: "12px",
              textAlign: "center",
              margin: 0,
            }}
          >
            © 2023 DHC GmbH
          </Text>
          <Text
            style={{
              ...paragraph,
              fontSize: "12px",
              textAlign: "center",
              margin: 0,
            }}
          >
            Sie erhalten diese E-Mail als Nachricht der DHC GmbH.
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

export default ScoreCardEmail;

const main = {
  backgroundColor: `${Colors.LightGrey}`,
  fontFamily:
    "Segoe UI, Helvetica, Helvetica Neue, -apple-system, BlinkMacSystemFont",
  padding: "10px 0",
};

const container = {
  backgroundColor: `${Colors.White}`,
  padding: "45px",
};

const heading = {
  fontSize: "24px",
  letterSpacing: "-0.5px",
  lineHeight: "1.3",
  fontWeight: "400",
  color: "#484848",
  padding: "10px 0 0",
};

const paragraph = {
  margin: "15px 0 15px",
  fontSize: "15px",
  lineHeight: "1.4",
  color: "#3c4149",
};

const buttonContainer = {
  margin: "27px 0 27px",
};

const button = {
  display: "block",
  paddingTop: "11px",
  paddingRight: "23px",
  paddingBottom: "11px",
  paddingLeft: "23px",
  // padding: "11px 23px 11px 23px",
  borderRadius: "3px",
  backgroundColor: `${Colors.MintGreen}`,
  fontWeight: "600",
  color: "#fff",
  fontSize: "15px",
  textDecoration: "none",
  textAlign: "center" as const,
};

const paragraphContent = {
  padding: "0 40px",
};

const hr = {
  borderColor: `${Colors.LightGrey}`,
  margin: "20px 0",
};
