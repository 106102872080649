import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import {
  QuestionWrapper,
  StyledFormLabel,
} from "../../globalStyles/QuestionWrapper.style";
import {
  isGeneralEvaluationCompleted,
  isSuccessfactorCompleted,
} from "../../helpers/checkCompletionHelpers";
import { addCustomerDimensions } from "../../services/serviceCalls";
import {
  Dimension,
  GeneralEvaluationDimension,
  Successfactor,
} from "../../types/ScorecardTypes";
import { useAppContext } from "../AppProvider/AppProvider";
import { StyledTextField } from "./TextFieldQuestion.style";

interface MultiLineTextFieldQuestionProps {
  question: string;
  placeholder: string;
  value: string;
  updateData: (input: string, question?: string) => void;
  isValidated: boolean;
  disabled?: boolean;
  setReloadDimension?: React.Dispatch<React.SetStateAction<boolean>>;
  currentSuccessfactor?: Successfactor;
  generalEvaluation?: GeneralEvaluationDimension;
  setCheckForCompletion?: React.Dispatch<React.SetStateAction<boolean>>;
  currentDimension?: Dimension;
  maxRows?: number;
  generalCompleted?: boolean;
  required?: boolean;
  isAdmin?: boolean;
}

const MultiLineTextFieldQuestion: FC<MultiLineTextFieldQuestionProps> = ({
  question,
  placeholder,
  value,
  updateData,
  isValidated,
  disabled,
  setReloadDimension,
  currentSuccessfactor,
  generalEvaluation,
  setCheckForCompletion,
  currentDimension,
  maxRows = 3,
  generalCompleted,
  required,
  isAdmin,
}) => {
  const { customerId } = useAppContext();
  const [input, setInput] = useState(value);

  const { customer_id } = useParams();

  const handleInputChange = (event: any) => {
    setInput(event.target.value);
    updateData(event.target.value, question);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // if generalAccordion is using MultiLineText gerneralDimesnion will be passed otherwise currentSuccessfactor will be passed
    if (
      event.key === "Tab" &&
      setReloadDimension &&
      question === "Begründung"
    ) {
      if (setCheckForCompletion) setCheckForCompletion(true);
      if (currentSuccessfactor) {
        if (!isSuccessfactorCompleted(currentSuccessfactor)) {
          event.preventDefault();
        } else {
          if (currentDimension)
            addCustomerDimensions(
              [currentDimension],
              isAdmin ? customer_id ?? "" : customerId ?? ""
            )
              .then((response) => {
                console.log(response);
                //check if Tab was pressed and trigger dimension reload
                setReloadDimension((prev: boolean) => !prev);
              })
              .catch((error) => {
                console.log(error);
              });
        }
      } else if (generalEvaluation) {
        if (!isGeneralEvaluationCompleted(generalEvaluation)) {
          event.preventDefault();
        } else {
          if (currentDimension)
            addCustomerDimensions(
              [currentDimension],
              isAdmin ? customer_id ?? "" : customerId ?? ""
            )
              .then((response) => {
                console.log(response);
                //check if Tab was pressed and trigger dimension reload
                setReloadDimension((prev: boolean) => !prev);
              })
              .catch((error) => {
                console.log(error);
              });
        }
      } else {
        if (currentDimension)
          addCustomerDimensions(
            [currentDimension],
            isAdmin ? customer_id ?? "" : customerId ?? ""
          )
            .then((response) => {
              console.log(response);
              setReloadDimension((prev: boolean) => !prev);
            })
            .catch((error) => {
              console.log(error);
            });
      }
    } else if (generalCompleted === false && event.key === "Tab") {
      event.preventDefault();
    }
  };

  const isError = input === "" && isValidated && required === true;

  return (
    <QuestionWrapper>
      <StyledFormLabel isError={isError} required={required}>
        {question}
      </StyledFormLabel>
      <StyledTextField
        isError={isError}
        error={isError}
        multiline
        rows={maxRows}
        placeholder={placeholder}
        value={input}
        onChange={handleInputChange}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        variant="outlined"
      />
    </QuestionWrapper>
  );
};

export default MultiLineTextFieldQuestion;
