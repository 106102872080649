import { FC, useEffect, useRef, useState } from "react";
import ErrorPage from "../../pages/StatePages/ErrorPage";
import InfoPage from "../../pages/StatePages/InfoPage";
import { useKeycloak } from "../KeycloakProvider/KeycloakProvider";
import CircularLoading from "../Loading/Loading";

interface ProtectedRouteProps {
  children?: React.ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoggedIn, initialized, doLogin, updateToken, doLogout } =
    useKeycloak();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );
  const [loginComplete, setLoginComplete] = useState<boolean>(false);

  // Define a function to reset logout timer and refresh token
  const resetLogoutTimerAndRefreshToken = () => {
    const minutes = 10;
    const inactivityLogoutTime = minutes * 60 * 1000;

    clearTimeout(timeoutRef.current); // Clear any previous logout timer

    // Set a new logout timer
    timeoutRef.current = setTimeout(() => {
      console.log("Logging out due to inactivity");
      doLogout();
    }, inactivityLogoutTime);
  };

  useEffect(() => {
    if (loginComplete) {
      console.log("starting Timeout");
      // Set up event listeners for user interaction
      const handleUserInteraction = () => {
        console.log("User Interaction");
        resetLogoutTimerAndRefreshToken();
      };

      window.addEventListener("click", handleUserInteraction);
      window.addEventListener("keydown", handleUserInteraction);

      // Initialize logout timer on first load
      resetLogoutTimerAndRefreshToken();

      // Cleanup function on component unmount
      return () => {
        clearTimeout(timeoutRef.current);
        window.removeEventListener("click", handleUserInteraction);
        window.removeEventListener("keydown", handleUserInteraction);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginComplete]);

  if (!initialized) {
    return <CircularLoading center />;
  }

  if (!isLoggedIn()) {
    console.log("There is no Token. Triggering login.");
    doLogin().catch((error) => {
      console.error("Error during login:", error);
    });
    return (
      <InfoPage text="Verbindung zum Authentifizierungsserver konnte nicht hergestellt werden." />
    );
  } else {
    updateToken((refreshed) => {
      if (refreshed) {
        console.log("Token refreshed");
      } else {
        console.log("Token still valid");
      }
      console.log("login complete");
      setLoginComplete(true);
    });
  }

  return children ? <>{children}</> : <ErrorPage />;
};

export default ProtectedRoute;
