import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReportIcon from "@mui/icons-material/Report";
import { Box, Breadcrumbs, Button } from "@mui/material";
import * as Colors from "../../globalStyles/Colors";

interface BreadcrumbItemProps {
  isCurrentItem?: boolean;
}

export const BreadcrumbItem = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isCurrentItem",
})<BreadcrumbItemProps>`
  text-transform: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  border-radius: 0;
  padding: ${(props) => (props.isCurrentItem ? "11px 0 9px 0" : "10px 0")};
  font-weight: 500;
  color: ${(props) => (props.isCurrentItem ? Colors.Black : Colors.Grey)};
  ${(props) =>
    props.isCurrentItem &&
    ` 
    pointer-events: none;
    border-bottom: 3px solid transparent; 
    border-image: linear-gradient(
      to right,
      ${Colors.Yellow} 20%,
      ${Colors.MintGreen} 100%
    ); 
    border-image-slice: 1;`};

  &:hover {
    background-color: ${Colors.BlackTransparent("0.1")};
  }
`;

export const NextIcon = styled(NavigateNextIcon)`
  color: ${Colors.Grey};
`;

export const CompletedIcon = styled(CheckCircleIcon)`
  color: ${Colors.MintGreen};
`;

export const InPorgressIcon = styled(CheckCircleOutlineIcon)`
  color: ${Colors.Yellow};
`;

export const MissingIcon = styled(ReportIcon)`
  color: ${Colors.Red};
`;

export const BreadcrumbNav = styled(Breadcrumbs)`
  background-color: ${Colors.White};
  width: 100%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  min-width: max-content;
  white-space: nowrap;
`;

interface NavBoxProps {
  isAdmin?: boolean;
}
export const NavBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isAdmin",
})<NavBoxProps>`
  ${(props) => !props.isAdmin && "overflow-x: auto; overflow-y: hidden;"}
  display: flex;
  width: 100%;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
`;
